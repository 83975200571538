import React from 'react';
import PublicLayout from './PublicLayout';
import { Route } from 'react-router-dom';

const PublicLayoutRoute = ({component: Component, ...rest}) => {
    return (
      <Route {...rest} render={matchProps => (
        <PublicLayout>
          <Component {...matchProps} />        
        </PublicLayout>
      )} />
    )
};

export default PublicLayoutRoute;
