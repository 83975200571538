import produce from 'immer';

import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  USER_SURVEY_BEGIN,
  USER_SURVEY_ERROR,
  DELETE_USER_BEGIN,
  DELETE_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  NEW_PASSWORD_BEGIN,
  NEW_PASSWORD_ERROR,
  SIGNUP_OAUTH_BEGIN,
  USER_SURVEY_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  NEW_PASSWORD_SUCCESS,
  SIGNUP_OAUTH_SUCCESS,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SELECT_FRAMEWORK_BEGIN,
  SELECT_FRAMEWORK_ERROR,
  SELECT_FRAMEWORK_SUCCESS,
} from '../actions/types';

const initialState = {
  user: null,
  loading: false,
  error: null,
  message: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LOGIN_BEGIN:
    case SIGNUP_BEGIN:
    case USER_SURVEY_BEGIN:  
    case UPDATE_USER_BEGIN:
    case DELETE_USER_BEGIN:
    case SIGNUP_OAUTH_BEGIN:
    case NEW_PASSWORD_BEGIN:
    case RESET_PASSWORD_BEGIN:
    case SELECT_FRAMEWORK_BEGIN:
      draft.loading = true;
      draft.error = null;
      draft.message = null;
      return;
    case SIGNUP_SUCCESS:
      draft.loading = false;
      if(action.payload.workflow === 2){
        draft.message = 'Thank you for signing up! Please check your email to continue.';
      }else if(action.payload.workflow === 1){
        draft.message = 'Thank you for signing up! Please check your email to continue.';
      }else{
        draft.message = 'Thank you for signing up! Please check your email to continue.';
      }
      return;
    case LOGIN_SUCCESS:
      draft.loading = false;
      if(action.payload.data == null) {
        draft.user = action.payload;
      } else {
        draft.user = action.payload.data.data;        
      }                  
      return;
    case USER_SURVEY_SUCCESS:
      draft.loading = false;
      draft.user = action.payload.data.data;
      return;
    case NEW_PASSWORD_SUCCESS:
      draft.loading = false;
      draft.message = 'Your password has been changed successfully.';
      return;
    case RESET_PASSWORD_SUCCESS:
      draft.loading = false;
      draft.message = 'We will send you an email to change your password.';
      return;
    case SIGNUP_OAUTH_SUCCESS:
    case DELETE_USER_SUCCESS:
      draft.loading = false;
      return;
    case UPDATE_USER_SUCCESS:
      draft.loading = false;
      draft.user = action.payload;
      draft.message = 'Change success';
      return;
    case SELECT_FRAMEWORK_SUCCESS:
      draft.loading = false;
      return;
    case LOGIN_ERROR:
    case SIGNUP_ERROR:
    case USER_SURVEY_ERROR:
    case DELETE_USER_ERROR:
    case NEW_PASSWORD_ERROR:
    case RESET_PASSWORD_ERROR:
    case SELECT_FRAMEWORK_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.user = null;
      return;
    case UPDATE_USER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      draft.message = action.error.error;
      return;    
    case LOGOUT_SUCCESS:
      draft.loading = false;
      draft.error = null;
      draft.user = null;
      return;
    default:
      return;
  }
}, initialState);

export default reducer;
