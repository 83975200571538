import React, { useState } from 'react';
import { Button, CircularProgress } from "@material-ui/core";
import '../assets/styles/Login.scss';
import Check from '../assets/images/check1.svg';

const RegisterForm = ({
  error,
  signup,
  message,
  loading
}) => {

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignup = (e) => {
    e.preventDefault();
    signup(email, name, password);
    setEmail('')
    setPassword('')
    setName('')
  }

  return (
    <div className="registrer-form">
      <h1>Welcome to <span>KOR Connect!</span></h1>
      <p className="muted-text">Create an account</p>
      <div className="form-container">
        <form onSubmit={handleSignup}>
          {error && (
            <div className="error-login">
              <span>{error}</span>
            </div>
          )}

          {loading && (
            <div className="loader-apis loader-account">
              <CircularProgress />
              <p>Creating account...</p>
            </div>
          )}

          <div className="error-login">
            {password && password.length < 10 && (
              <span>Password is too short (minimum 10 characters)</span>
            )}
          </div>
          {message && (
            <div className="success-login">
              <img src={Check} />
              <p>{message}</p>
            </div>
          )}

          <div className="input-container">
            <p>Name</p>
            <input
              autoFocus
              placeholder="Your Name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <p>Email</p>

          <div className="input-container">
            <input
              placeholder="youremail@email.com"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>

          <div className="input-container">
            <p>Password</p>

            <input
              placeholder="+10 characters"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <Button
            id="signup"
            type="submit"
            color="primary"
            variant="contained"
            disabled={password && password.length < 10}
          >
            Sign Up
          </Button>
        </form>
      </div>
    </div>
  );
}

export default RegisterForm;
