import React from 'react';
import './App.scss';
import Home from './pages/Home';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ConfirmAccount from './pages/ConfirmAccount';
import ErrorPage from './pages/ErrorPage';
import Api from './pages/APIDetails';
import SettingsPage from './pages/SettingsPage';
import ResetPassword from './pages/ResetPassword';
import AppLayoutRouter from './layouts/AppLayoutRouter';
import PublicLayoutRouter from './layouts/PublicLayoutRouter';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';

const App = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
           <Redirect to="/login" />
          </Route>
          <PublicLayoutRouter path="/login" component={Login} />
          <PublicLayoutRouter path="/ResetPassword" component={ResetPassword} />
          <PublicLayoutRouter path="/ConfirmAccount" component={ConfirmAccount} />
          <PublicLayoutRouter path='/ErrorPage' component={ErrorPage} />
          <PublicLayoutRouter path='/SignUp' component={SignUp} />

          <AppLayoutRouter path="/home" component={Home} /> 
          <AppLayoutRouter path="/ConnectionDetails/:secureGatewayId" component={Api} />
          <AppLayoutRouter path="/Settings" component={SettingsPage} />
        </Switch>
      </BrowserRouter>  
    </div>
  );
}

export default App;
