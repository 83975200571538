import { fetchAwsApiGateway, newAwsApiGateway } from '../api/aws/aws-api-gateway';
import { GET_AWS_API_BEGIN, GET_AWS_API_SUCCESS, GET_AWS_API_ERROR } from './types';

export const getAwsApiGateway = () => {
  return dispatch => {
    dispatch({ type: GET_AWS_API_BEGIN });
    fetchAwsApiGateway()
      .then(response => {
        if (!response.data.projects) {
          newAwsApiGateway()
            .then(response => {
              const payload = response.data.project;
              dispatch({
                type: GET_AWS_API_SUCCESS,
                payload
              });
            })
            .catch(error => {
              dispatch({
                type: GET_AWS_API_ERROR,
                error: error.response.data.error
              });
          });
        } else {
          const payload = response.data.projects;
          dispatch({
            type: GET_AWS_API_SUCCESS,
            payload
          });
        }
      })
      .catch(error => {
        dispatch({
          type: GET_AWS_API_ERROR,
          error: error.response.data.error
        }); 
    });
  }
};
