import React, { useState } from "react";
import badgeCode from "../constants/badgeCode";
import SyntaxHighlighter from "react-syntax-highlighter";
import angularCodeHTML from "../constants/angularCodeHTML";
import angularCodeIndex from "../constants/angularCodeIndex";
import angularCodeAppModule from "../constants/angularCodeAppModule";
import angularCodeComponent from "../constants/angularCodeComponent";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Snackbar } from '@material-ui/core';

const AngularSnippet = ({ secureGateway, selectFramework }) => {
  const [ open, setOpen ] = useState(false);

  const handleSelectFrameworkAngular = () => {
    selectFramework('angular');
    const codeangular = 'npm install --save ngx-captcha';
    navigator.clipboard.writeText(codeangular);
    setOpen(true);
  }

  const handleSelectFrameworkAngular2 = () => {
    selectFramework('angular');
    const codeangular = angularCodeIndex;
    navigator.clipboard.writeText(codeangular);
    setOpen(true);
  }

  const handleCopy = (code) => {
    navigator.clipboard.writeText(code);
    setOpen(true);
  }

  const codeSecure = '<snippet example>	axios.get("'+secureGateway.secure_url_endpoint+'"... </snippet example>';

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };



  return (
    <div className="snippets-section">
     <p className="title-snippets">Setup KOR Connect In Your Project</p>
      <p>These snippets will help you configure an <b>Angular project</b> in order to use KOR Connect.</p>
      <p className="mt-2 mb-4"><i> If you have an existing project copy the parts of the code that you need which are noted with code comments.</i></p>
      <p className="mt-2 mb-2">Example link: <a href={process.env.REACT_APP_ANGULAR_SNIPPET} target="_blank">click here</a></p>
      <p>Install dependencies</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleSelectFrameworkAngular()}>
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        {secureGateway && (
          <SyntaxHighlighter
            language="bash"
            style={nightOwl}
            showLineNumbers={true}
            wrapLines={true}
          >
            npm install --save ngx-captcha
          </SyntaxHighlighter>
        )}
      </div>
      <p>Add the following script to your index.html project:</p>
      
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleSelectFrameworkAngular2()}>
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        <SyntaxHighlighter
          language="html"
          style={nightOwl}
          showLineNumbers={true}
          wrapLines={true}
        >
          {angularCodeIndex}
        </SyntaxHighlighter>
      </div>
      <p>Add the following script to your app.module.ts project:</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleCopy(angularCodeAppModule)}>
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        <SyntaxHighlighter
          language="ts"
          style={nightOwl}
          showLineNumbers={true}
          wrapLines={true}
        >
          {angularCodeAppModule}
        </SyntaxHighlighter>
      </div>
      <p>Add the following script to your your.component.ts project:</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleCopy(angularCodeComponent(
              secureGateway.recaptcha_key,
              secureGateway.secure_url_endpoint,
              secureGateway.usage_plan_token
            ))}>
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        <SyntaxHighlighter
          language="ts"
          style={nightOwl}
          showLineNumbers={true}
          wrapLines={true}
        >
          {angularCodeComponent(
            secureGateway.recaptcha_key,
            secureGateway.secure_url_endpoint,
            secureGateway.usage_plan_token
          )}
        </SyntaxHighlighter>
      </div>
      <p>Add the following script to your your.componet.html project:</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleCopy(angularCodeHTML)}
            >
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        <SyntaxHighlighter
          language="js"
          style={nightOwl}
          showLineNumbers={true}
          wrapLines={true}
        >
          {angularCodeHTML}
        </SyntaxHighlighter>
      </div>
      
      <p className="title-snippets">How to Use the Secure URL</p>
      <p>The secure URL provided by KOR that you see in the snippet is a replacement for the API URL that you added when creating the connection.</p>
      <div className="snippet-code html-snippet">

        {secureGateway && (
          <SyntaxHighlighter
            language="html"
            style={nightOwl}
            showLineNumbers={true}
            wrapLines={true}
          >
            {codeSecure}
          </SyntaxHighlighter>
        )}
      </div>
      <p>The secure URL above should append the endpoint that you need to call. For example, if your API URL is <u>api.example.com</u> and the endpoint you want to call is <u>/hello_world</u> the normal API call would look like this: <u>api.example.com/hello_world</u>, with the secure URL provided by KOR Connect this API URL would be exchanged and the result, should look like this: <u>{secureGateway.secure_url_endpoint}/hello_world.</u></p>


      <p className="title-snippets">(OPTIONAL) Remove Badge</p>
      <p>Add the following script to your HTML project:</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleCopy(badgeCode)}
            >
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        {secureGateway && (
          <SyntaxHighlighter
            language="html"
            style={nightOwl}
            showLineNumbers={true}
            wrapLines={true}
          >
            {badgeCode}
          </SyntaxHighlighter>
        )}
      </div>
      <p>Add the following script to your CSS project:</p>
      <div className="copy-snippet-button">
          <Button          
            color="primary"
            variant="contained"
            onClick={() => handleCopy(`.grecaptcha-badge { visibility: hidden; }`)}
            >
              Copy Snippet
          </Button>
      </div>
      <div className="snippet-code">
        {secureGateway && (
          <SyntaxHighlighter
            language="css"
            style={nightOwl}
            showLineNumbers={true}
            wrapLines={true}
          >
            {`.grecaptcha-badge { visibility: hidden; }`}
          </SyntaxHighlighter>
        )}
      </div>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={2000}
        message="The snippet code has been copied."
      />
    </div>
  );
};

export default AngularSnippet;
