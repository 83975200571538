import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import closeIcon from '../../assets/images/closeIcon.svg';
import LogOutButton from '../../assets/images/LogOutButton.svg';
import { Button } from '@material-ui/core';
import routes from '../../constants/routes';
import { logout } from '../../actions/user.actions';

const MenuResponsive = withRouter(
    ({ history, logout, handleShowMenu, handleLogOut }) => {

        const handleViewSettings = () => {
            history.push(`${routes.SETTINGS}`);
            handleShowMenu();
        }

        const handleGoHome = () => {
            history.push(`${routes.HOME}`);
            handleShowMenu();
        }

        return (
            <>
                <div
                    className="container-modal-kor menu-responsive-1"
                >
                    <div className="background-opacity">

                    </div>

                    <div className="content-modal">
                        <div className="title-modal">
                            <div className="close-modal" onClick={() => handleShowMenu()}>
                                <img src={closeIcon} />
                                <p>Menu</p>
                            </div>
                        </div>
                        <div className="menu-sections">
                            <div className="menu-section"
                                onClick={handleGoHome}
                            >
                                <p>Home</p>
                            </div>
                            <div className="menu-section"
                                onClick={() => handleViewSettings()}
                            >
                                <p>Settings</p>
                            </div>
                            <div className="menu-section">
                                <a href="https://kor-comunity.gitlab.io/kor-connect/" id="navDocs" target="_blank">
                                    <p>Help</p>
                                </a>
                            </div>
                            <div className="menu-section">
                                <a href="https://discourse.korconnect.io/"  target="_blank">
                                    <p>Community</p>
                                </a>
                            </div>
                            <div className="log-out-button">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleLogOut()}
                                >
                                    <img src={LogOutButton} /> Log Out
                                </Button>
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    })

const mapState = (state) => ({

})

const mapDispatch = {
    logout
}

export default connect(
    mapState,
    mapDispatch
)(MenuResponsive);