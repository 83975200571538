import React, { useEffect } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const LoadingBoxDomains = (props) => {    
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 1));
    }, 128);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

    return (
        <>
            {props.loading && (
                <div className="loading-mode">
                    <div className="progress-bar">
                      <LinearProgressWithLabel value={progress} />
                    </div>
                    <div className="text-progress">
                        <p>Please wait while we update your settings.</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default LoadingBoxDomains;
