const vueCodeApp = (URL, usagePlanToken) => {
  return `<template>
  <!-- Extend this sample application by modifying this code -->
  <button @click="recaptcha">Execute recaptcha</button>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";
// This library is required for KOR Connect

export default {
  name: 'App',
  // This function is an example of how to call your API through KOR Connect
  setup() {
    // This constant is required for ReCaptcha, which is used by KOR Connect 
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // We'll need this constant to make request 
      const token = await executeRecaptcha("submit");

      // We'll need this constant to make request
      const timestamp = new Date().toUTCString();
      const headers = {
        //Place your headers here:
        token, 
        timestamp, 
        'x-api-key': '${usagePlanToken}' 
      };
      // You need to append the path of the endpoint you are calling to the KOR Connect base URI 
      fetch('https://${URL}', { headers })
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log(error));
    };

    return {
      recaptcha,
    };
  }
}
</script>

<style>
/* This is used to hide your Recaptcha badge (please read Recaptcha’s TOS) */
.grecaptcha-badge { visibility: hidden; }
</style>`;
};

export default vueCodeApp;
