import React, { useState } from "react";
import { Button } from "@material-ui/core";

const LoginForm = ({ error, login, message }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = (e) => {
    e.preventDefault();
    login(email, password);
  };

  return (
    <div className="registrer-form">
      <h1>
        Welcome to <span>KOR Connect!</span>
      </h1>
      <p className="muted-text">
        {" "}
        To integrate your APIs login to your account
      </p>
      <div className="form-container">
        <form onSubmit={handleSignIn}>
          <div className="error-login">{error && <span>{error}</span>}</div>
          {message && 
          <div className="success-login">
            <span>{message}</span>
          </div>  }
          <div className="input-container">
            <p>Email</p>
            <input
              autoFocus
              placeholder="youremail@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="input-container">
            <p>Password</p>
            <input
              type="password"
              value={password}
              placeholder="Your password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <Button id="sigin" color="primary" variant="contained" type="submit">
            Sign In
          </Button>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
