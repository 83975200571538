import React, { useEffect, useState } from "react";
import ReactGa from "react-ga";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import LoginForm from "../components/LoginForm";
import RegisterForm from "../components/RegisterForm";
import ResetPasswordForm from "../components/ResetPasswordForm";
import routes from "../constants/routes";
import {
  login,
  signup,
  resetPassword,
  loginWithOauth,
  signupGithubOauth,
  signupGoogleOauth,
} from "../actions/user.actions";
import "../assets/styles/Login.scss";
import KORIcon from "../assets/images/KorIcon.svg";
import KORBackground from "../assets/images/korBackground.svg";
import { withRouter } from "react-router-dom";

const SignUp = withRouter(({
  error,
  login,
  signup,
  loading,
  message,
  location,
  currentUser,
  resetPassword,
  loginWithOauth,
  signupGithubauth,
  signupGoogleOauth,
  history
}) => {
  const [register, setRegister]                  = useState(false);
  const [showRestPassword, setShowResetPassword] = useState(false);

  useEffect(() => {
    if (location.search.length > 1) {
      loginWithOauth(location.search);
    }
  }, [location]);

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGa.pageview(`${window.location.pathname}${window.location.search}`);
  }, []);

  if (currentUser) {
    if (currentUser.confirmed) {
      return <Redirect to="/home" noThrow />;
    }
  }

  const handleGoSignIn = () => {
    history.push(`${routes.LOGIN}`);
  }

  return (
    <>
      <div className="app-container">
        <div className="app-header">
          <div className="kor-connect">
            <a href="http://korconnect.io" target="_blank">
              <img src={KORIcon} />
            </a>
          </div>

          {showRestPassword ? (
            <div>
              <Button onClick={() => setShowResetPassword(false)}>Back</Button>
            </div>
          ) : (
            <div>
              {register ? (
                <p>Already have an account?</p>
              ) : (
                <p>You don't have an account yet?</p>
              )}
                <Button onClick={() => handleGoSignIn()}>Sign In</Button>
            </div>
          )}
        </div>

        <div className="main-page">
          <div className="image-kor-main">
            <img src={KORBackground} />
          </div>

          <div className="login-signup-form">
              <div>
                  <RegisterForm
                    error={error}
                    signup={signup}
                    loading={loading}
                    message={message}
                  />
              </div>

            {!showRestPassword && (
              <div className="muted-text-login">
                  <p>
                    Creating an account means you're okay with our{" "}
                    <span>Terms of Service</span> and <span>Privacy Policy</span>
                  </p>
              </div>
            )}    
          </div>
        </div>
      </div>
    </>
  );
});

const mapState = (state, props) => ({
  error: state.user.error,
  location: props.location,
  message: state.user.message,
  loading: state.user.loading,
  currentUser: state.user.user,
});

const mapDispatch = {
  login,
  signup,
  resetPassword,
  loginWithOauth,
  signupGithubOauth,
  signupGoogleOauth,
};

export default connect(mapState, mapDispatch)(SignUp);
