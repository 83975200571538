import React, { useState } from 'react';
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import LifebuoyIcon from '../assets/images/LifebuoyIcon.svg';
import closeIcon from '../assets/images/closeIcon.svg';
import WarningIcon from '../assets/images/WarningIcon.svg';
import { Button } from "@material-ui/core";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const TopBarConnection = (props) => {
    const [value, setValue] = useState('');
    const [rateLimitValue, setRateLimitValue] = useState('');
    const [open, setOpen] = useState(true);


    const handleEnviroment = (e) => {
        var totalDomains = props.secureGateway.allowed_origins.split(",").length;
        setValue(e);
        props.setTypeUpdate('enviroment');

        if (totalDomains < 2) {
            if (e === 'production') {
                props.setErrorDomains(true)
            } else {
                props.updateEnvironment(props.secureGateway.id, e);
                props.setErrorDomains(false)
            }
        } else {
            props.updateEnvironment(props.secureGateway.id, e);
            props.setErrorDomains(false)
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleRateLimit = (rateLimit) => {
        var reg = /^\d+$/;
        props.setTypeUpdate('rateLimit');
        setRateLimitValue(rateLimit);

        if (rateLimit === '0') {
            props.setErrorRateLimit(true)
        }

        if (reg.test(rateLimit)) {
            props.setErrorRateLimit(false)
        } else {
            props.setErrorRateLimit(true)
        }
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    return (
        <div className="top-bar-connection-ctn">
            {props.secureGateway && (
                <>
                    {props.secureGateway.rate_limit ? (
                        <>
                            {!props.hideRateLimiter ? (
                                <HtmlTooltip
                                    open={open}
                                    placement="right"
                                    title={
                                        <React.Fragment>
                                            <div className="tooltip-warning-rate">
                                                <img src={closeIcon} onClick={() => handleClose()} />
                                                <p><b>{"Warning:"}</b> {"To avoid being blocked make sure to set your rate limit to the appropriate number for your testing needs; remember to also set your production rate limit after testing."}</p>
                                            </div>
                                        </React.Fragment>
                                    }
                                >
                                    <div className="rate-limit-ctn">
                                        <div>
                                            <p>Rate Limit:</p>
                                            <div className="input-ctn-rate">
                                                <input
                                                    placeholder={props.secureGateway.rate_limit}
                                                    onChange={(e) => handleRateLimit(e.target.value)}
                                                />
                                                <p>/second per source</p>
                                                <Tooltip title="What is this?">
                                                    <a href="https://kor-comunity.gitlab.io/kor-connect/adir/RATELIMITER.html" target="_blank">
                                                        <img src={LifebuoyIcon} />
                                                    </a>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={!rateLimitValue || rateLimitValue === '0' || props.errorRateLimit}
                                                onClick={() => props.updateRateLimit(props.secureGateway.id, rateLimitValue)}
                                            >
                                                Update
                                            </Button>
                                        </div>
                                    </div>
                                </HtmlTooltip>
                            ) : (
                                <div className="rate-limit-ctn">
                                    <div>
                                        <p>Rate Limit:</p>
                                        <div className="input-ctn-rate">
                                            <input
                                                placeholder={props.secureGateway.rate_limit}
                                                onChange={(e) => handleRateLimit(e.target.value)}
                                            />
                                            <p>/second per source</p>
                                            <Tooltip title="What is this?">
                                                <a href="https://kor-comunity.gitlab.io/kor-connect/adir/RATELIMITER.html" target="_blank">
                                                    <img src={LifebuoyIcon} />
                                                </a>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            disabled={!rateLimitValue || rateLimitValue === '0' || props.errorRateLimit}
                                            onClick={() => props.updateRateLimit(props.secureGateway.id, rateLimitValue)}
                                        >
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className="rate-limit-ctn rate-limit-opacity-0">

                        </div>
                    )}

                </>
            )}

            {props.secureGateway && (
                <div className="connection-security-ctn">
                    <>
                        {props.secureGateway.connection_mode === 'testing' && (
                            <div className="connection-mode-ctn warning-ctn-conn">
                                {props.secureGateway && (
                                    <div className="connection-mode-warning">
                                        <Tooltip title="Testing Mode is enabled – do not forget to disable it.">
                                            <img src={WarningIcon} />
                                        </Tooltip>
                                        <div>
                                            <p>Connection Mode:</p>
                                            <RadioGroup
                                                row aria-label="gender"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => handleEnviroment(e.target.value)}
                                                value={value}
                                            >
                                                <FormControlLabel value="testing" control={<Radio />} label="Testing" checked={props.secureGateway.connection_mode === 'testing'} />
                                                <FormControlLabel value="production" control={<Radio />} label="Production" checked={props.secureGateway.connection_mode === 'production'} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <Tooltip title="What is this?">
                                        <a href="https://kor-comunity.gitlab.io/kor-connect/adir/TESTINGPRODUCTIONMODES.html" target="_blank">
                                            <img src={LifebuoyIcon} />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                        )}

                        {props.secureGateway.connection_mode === 'production' && (
                            <div className="connection-mode-ctn">
                                {props.secureGateway && (
                                    <div className="connection-mode-warning">
                                        <div>
                                            <p>Connection Mode:</p>
                                            <RadioGroup
                                                row aria-label="gender"
                                                name="row-radio-buttons-group"
                                                onChange={(e) => handleEnviroment(e.target.value)}
                                                value={value}
                                            >
                                                <FormControlLabel value="testing" control={<Radio />} label="Testing" checked={props.secureGateway.connection_mode === 'testing'} />
                                                <FormControlLabel value="production" control={<Radio />} label="Production" checked={props.secureGateway.connection_mode === 'production'} />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                )}
                                <div>
                                    <Tooltip title="What is this?">
                                        <a href="https://kor-comunity.gitlab.io/kor-connect/adir/TESTINGPRODUCTIONMODES.html" target="_blank">
                                            <img src={LifebuoyIcon} />
                                        </a>
                                    </Tooltip>
                                </div>
                            </div>
                        )}

                    </>

                    <div className="security-type-ctn">
                        <p>Security Type:</p>
                        {props.secureGateway.security_level === '1' ? (
                            <p>Single URL</p>
                        ) : (
                            <p>Single URL + Additional Security</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default TopBarConnection;

