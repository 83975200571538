const industry = [
    { name: 'Software', code: '01' },
    { name: 'Cloud Services', code: '02'},
    { name: 'Internet', code: '03' },
    { name: 'Telecom', code: '04' },
    { name: 'Infrastructure', code: '05' },
    { name: 'Artificial Intelligence', code: '06' },
    { name: 'Design', code: '07' },
    { name: 'Games', code: '08' },
    { name: 'Entertaiment', code: '09' },
    { name: 'Education', code: '10' },
    { name: 'Healthcare', code: '11' },
    { name: 'Science', code: '12' },
    { name: 'Media', code: '13' },
    { name: 'Music', code: '14' },
    { name: 'Other', code: '15' }
];

export default industry;
