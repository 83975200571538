import {
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  USER_SURVEY_SUCCESS,
} from "./actions/types";
import { validToken } from "./api/users";
import routes from "./constants/routes.json";

const storeHeaders = (headers) => {
  localStorage.setItem("currentHeaders", JSON.stringify(headers));
};

const storeUser = (user) => {
  localStorage.setItem("currentUser", JSON.stringify(user));
};

export const saveAuthToken = (store) => (next) => (action) => {
  if (action.type === LOGIN_SUCCESS || action.type === SIGNUP_SUCCESS) {
    if (action.payload) {
      if (action.payload.data == null) {
        // Load from localStorage
        storeUser(action.payload);
      } else {
        // Load user from API
        storeUser(action.payload.data.data);
        storeHeaders(action.payload.headers);
      }
    }
  }

  if (action.type === USER_SURVEY_SUCCESS) {
    storeUser(action.payload.data.data);
  }
  if (action.type === LOGOUT_SUCCESS) {
    localStorage.clear();
  }
  next(action);
};

export const catchUnauth = (store) => (next) => (action) => {
  let actionType = String(action.type);
  const headers = localStorage.getItem("currentHeaders");
  if (actionType.includes("ERROR")) {
    if (action.error) {
      if (action.error.response) {
        let status = action.error.response.status;
        if (status) {
          if (
            status === 401 &&
            window.location.href.substr(window.location.href.length - 6) !==
              "login"
          ) {
            if (!headers || headers === "undefined") {
              localStorage.clear();
              window.location.replace(routes.LOGIN);
            } else {
              validToken()
                .then((response) => {
                  if (!response.data.success) {
                    localStorage.clear();
                    window.location.replace(routes.LOGIN);
                  }
                })
                .catch((error) => {
                  localStorage.clear();
                  window.location.replace(routes.LOGIN);
                });
            }
          }
        }
      }
    } else {
      localStorage.clear();
      window.location.replace(routes.LOGIN);
    }
  }

  next(action);
};
