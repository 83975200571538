import React from 'react';
import Check from '../assets/images/check1.svg';
import LoadingBox from '../components/LoadingBox';
import LoadingBoxSecurity from '../components/LoadingBoxSecurity';
import LoadingBoxRateLimit from '../components/LoadingBoxRateLimit';
import LoadingBoxDomains from '../components/LoadingBoxDomains';

const ConnectionStatus = (props) => {
  return (
    <>
      {!props.loading ? (
        <div className="badge-status-connection">
          <img src={Check} />
          <p>Connection Ready</p>

        </div>
      ) : (
        <>

          {props.typeUpdate === 'rateLimit' && (
            <LoadingBoxRateLimit
              loading={props.loading}
            />
          )}

          {props.typeUpdate === 'securityType' && (
            <LoadingBoxSecurity
              loading={props.loading}
            />
          )}

          {props.typeUpdate === 'domains' && (
            <LoadingBoxDomains
              loading={props.loading}
            />
          )}

          {props.typeUpdate != 'rateLimit' && props.typeUpdate != 'securityType' && props.typeUpdate != 'domains' && (
            <LoadingBox
              loading={props.loading}
            />
          )}
        </>

      )}
    </>
  );
}

export default ConnectionStatus;
