const heardFromUs = [
    { name: 'Search engine (Google, yahoo etc)', code: '01' },
    { name: 'Recommended by friend or colleague', code: '02'},
    { name: 'Social media', code: '03' },
    { name: 'Youtube Ads', code: '04' },
    { name: 'Other paid social media advertising', code: '05' },
    { name: 'Blog or publication', code: '06' },
    { name: 'Other', code: '15' }
];

export default heardFromUs;
