import React, { useState, useEffect } from "react";
import "../assets/styles/ModalKor.scss";
import { Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const AddDomainForm = ({
  domains,
  setDomains,
  secureGateway,
  updateDomains,
  setShowDomainsFields,
  handleShowAddDomainModal,
  setTypeUpdate
}) => {
  const [validator, setValidator] = useState(false);
  const [stringValidator, setStringValidator] = useState(true);

  const handleNewField = () => {
    if (!validator) {
      setDomains((currenField) => [...currenField, ""]);
    }
    setStringValidator(false);
  };

  const handleAddValue = (event, index) => {
    if (event.target.value === "localhost") {
      setValidator(true);
    } else {
      setValidator(false);
    }

    if (event.target.value.length > 3) {
      setStringValidator(true);
    } else {
      setStringValidator(false);
    }
    const domain = event.target.value;
    const field = [...domains];
    field[index] = domain;
    setDomains(field);
  };

  const handleUpdateDomains = () => {
    setTypeUpdate('domains');
    const id = secureGateway.id;
    updateDomains(id, domains);
    setShowDomainsFields(false);
    handleShowAddDomainModal();
  };

  const handleRemoveField = (index) => {
    if (
      (domains[index] !== "localhost" &&
        secureGateway.connection_mode === "testing") ||
      secureGateway.connection_mode === "production"
    ) {
      setDomains((currentField) =>
        currentField.filter((domain, i) => i !== index)
      );
    }
  };

  useEffect(() => {
    domains.forEach((domain) => { 
      if (domain.length < 4) { 
        setStringValidator(false);   
        return;
      }
    });
  }, [domains]);

  return (
    <>
      {validator && <Alert severity="error">You can't add localhost</Alert>}
      {domains.map((domain, index) => (
        <div className="input-modal allowed-modal" key={index}>
          <p>{`Domain ${index + 1}`}</p>
          <input
            placeholder="Domains"
            value={domain}
            disabled={
              domain === "localhost" &&
              secureGateway.connection_mode === "testing"
            }
            onChange={(e) => handleAddValue(e, index)}
          />
          <span
            className="ml-4 pointer"
            onClick={() => handleRemoveField(index)}
          >
            X <p>Remove</p>
          </span>
        </div>
      ))}

      <div className="input-modal-add">
        <p onClick={() => handleNewField()}>+ Add Domain</p>
      </div>

      <div className="input-modal-add-button mt-5 mb-3">
        <Button
          id="detailUpdateDomians"
          disabled={validator || !stringValidator}
          onClick={() => handleUpdateDomains()}
          color="primary"
          variant="contained"
        >
          Update
        </Button>
      </div>
    </>
  );
};

export default AddDomainForm;
