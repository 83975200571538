import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const fetchGateways = () => { 
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));

  return axios.get(`${URL}/gateway`, { headers: authToken });
};

export const createGateway = data => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));

  return axios.post(`${URL}/gateway`, data, { headers: authToken });
};

export const updateGateway = (id, data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/update_gateway/${id}`, data, { headers: authToken });
};

export const showGateway = id => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.get(`${URL}/gateway/${id}`, { headers: authToken });
}

export const deleteGateway = id => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.delete(`${URL}/gateway/${id}`, { headers: authToken });
};

export const changeRateLimit = (id, rateLimit) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));
  return axios.put(`${URL}/update_gateway/${id}`, rateLimit, { headers: authToken });
};