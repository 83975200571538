import React from 'react';

const PublicLayout = ({ children }) => {
  return (
    <>
      {children}
    </>
  );
}

export default PublicLayout;
