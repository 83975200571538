import React, { useState, useEffect } from "react";
import ReactGa from "react-ga";
import Moment from "moment";
import { connect } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { withRouter } from "react-router-dom";
import "../assets/styles/APIDetailsPage.scss";
import SingleURL from "../components/SingleURL";
import APIDetailsC from "../components/APIDetails";
import SecurityType from "../components/SecurityType";
import TopBarConnection from "../components/TopBarConnection";
import { selectFramework } from "../actions/user.actions";
import AdditionalSecurity from "../components/AdditionalSecurity";
import routes from "../constants/routes";
import {
  updateDomains,
  getSecureGateway,
  editSecureGateway,
  updateEnvironment,
  updateRateLimit,
  removeGateway,
} from "../actions/secure-gateway.action";
import MenuAPIDetails from "../components/MenuAPIDetails";

const ApiDetails = withRouter(
  ({
    error,
    params,
    loading,
    secureGateway,
    updateDomains,
    selectFramework,
    getSecureGateway,
    editSecureGateway,
    updateEnvironment,
    typeSecurity,
    updateRateLimit,
    removeGateway,
    history,
  }) => {
    const [hideRateLimiter, setHideRateLimiter] = useState(true);
    const [selectTab, setSelectTab] = useState("single");
    const [typeUpdate, setTypeUpdate] = useState("");
    const [errorRateLimit, setErrorRateLimit] = useState(false);
    const [errorDomains, setErrorDomains] = useState(false);
    const [showDelete2, setShowDelete2] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const handleDeleteConnection = () => {
      removeGateway(secureGateway);
      setShowDelete(!showDelete);
      setTimeout(() => {
        setShowDelete2(!showDelete2);
      }, 900);
      history.push(`${routes.HOME}`);
    };

    const handleShowDelete = () => {
      setShowDelete(!showDelete);
      console.log(secureGateway);
      setTimeout(() => {
        setShowDelete2(!showDelete2);
      }, 900);
    };

    const handleCloseModalDelete = () => {
      setShowDelete(!showDelete);
      setTimeout(() => {
        setShowDelete2(!showDelete2);
      }, 900);
    };

    const handleValidationDate = () => {
      const today = "Dec 16, 2021 10:46 AM";
      if (secureGateway) {
        const dateConnection = Moment(secureGateway.created_at).format("lll");
        if (today > dateConnection) {
          setHideRateLimiter(false);
        }
      }
    };

    useEffect(() => {
      if (!secureGateway) {
        getSecureGateway(params.secureGatewayId);
      }
    }, []);

    useEffect(() => {
      ReactGa.initialize(process.env.REACT_APP_TRACKING_ID);
      ReactGa.pageview(`${window.location.pathname}${window.location.search}`);
      handleValidationDate();
    }, []);

    return (
      <>
        <div className="kor-page-container">
          <div className="api-gateway-details-container">
            <MenuAPIDetails
              secureGateway={secureGateway}
              updateEnvironment={updateEnvironment}
              setSelectTab={setSelectTab}
              selectTab={selectTab}
            />

            <div className="details-ctn">
              <div className="api-details">
                <TopBarConnection
                  secureGateway={secureGateway}
                  updateEnvironment={updateEnvironment}
                  typeUpdate={typeUpdate}
                  setTypeUpdate={setTypeUpdate}
                  updateRateLimit={updateRateLimit}
                  errorRateLimit={errorRateLimit}
                  setErrorRateLimit={setErrorRateLimit}
                  typeUpdate={typeUpdate}
                  setTypeUpdate={setTypeUpdate}
                  setErrorDomains={setErrorDomains}
                  hideRateLimiter={hideRateLimiter}
                />
                {error && <Alert severity="error">Error: {error}</Alert>}

                {errorRateLimit && (
                  <Alert severity="error">
                    Error: You can only use whole numbers greater than 0.
                  </Alert>
                )}

                {errorDomains && (
                  <Alert severity="error">
                    Error: You need to add at least one domain to change your
                    connection to production.
                  </Alert>
                )}
                <div className="details-ctn details-ctn-1">
                  {secureGateway && (
                    <>
                      <SecurityType
                        selectTab={selectTab}
                        secureGateway={secureGateway}
                        editSecureGateway={editSecureGateway}
                        loading={loading}
                        typeSecurity={typeSecurity}
                        typeUpdate={typeUpdate}
                        setTypeUpdate={setTypeUpdate}
                      />
                      {selectTab === "single" && (
                        <SingleURL
                          secureGateway={secureGateway}
                          setSelectTab={setSelectTab}
                          selectTab={selectTab}
                          loading={loading}
                        />
                      )}

                      {selectTab === "security" && (
                        <AdditionalSecurity
                          secureGateway={secureGateway}
                          selectFramework={selectFramework}
                          selectTab={selectTab}
                          loading={loading}
                        />
                      )}

                      {selectTab === "details" && (
                        <APIDetailsC
                          secureGateway={secureGateway}
                          updateDomains={updateDomains}
                          updateEnvironment={updateEnvironment}
                          editSecureGateway={editSecureGateway}
                          loding={loading}
                          handleDeleteConnection={handleDeleteConnection}
                          handleShowDelete={handleShowDelete}
                          setShowDelete={setShowDelete}
                          showDelete={showDelete}
                          handleCloseModalDelete={handleCloseModalDelete}
                          typeUpdate={typeUpdate}
                          setTypeUpdate={setTypeUpdate}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
);

const mapState = (state, props) => ({
  params: props.match.params,
  error: state.secureGateway.error,
  loading: state.secureGateway.loading,
  secureGateway: state.secureGateway.secureGateway,
  typeSecurity: state.secureGateway.typeUpdate,
});

const mapDispatch = {
  updateDomains,
  selectFramework,
  getSecureGateway,
  editSecureGateway,
  updateEnvironment,
  updateRateLimit,
  removeGateway,
};

export default connect(mapState, mapDispatch)(ApiDetails);
