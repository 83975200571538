import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';
import ConnectionStatus from './ConnectionStatus';


const SecurityType = ({ 
  selectTab, 
  secureGateway, 
  editSecureGateway,
  loading,
  typeSecurity, 
  setTypeUpdate, 
  typeUpdate }) => {

  const [activate, setActivate] = useState(false);

  useEffect(() => {
    secureGateway.security_level === '2' ?
      setActivate(true) : setActivate(false);
  }, [secureGateway]);

  const handleChangeSecurity = () => {
    setTypeUpdate('securityType');
    if (secureGateway.security_level === '2') {
      setActivate(false);
      editSecureGateway(secureGateway.id, '1', 'security_level');
    } else {
      setActivate(true);
      editSecureGateway(secureGateway.id, '2', 'security_level');
    }
  }

  return (
    <div className="security-type-badge">
      <ConnectionStatus
        loading={loading}
        typeSecurity={typeSecurity}
        typeUpdate={typeUpdate}
      />
      <div className="type-flex">
        {selectTab === 'single' && (
          <>
          </>
        )}
        {selectTab === 'security' && (
          <div className="type-ctn">
            {(secureGateway.security_level === '1' || secureGateway.security_level === '2') && (
              <div className="text-switch">
                <p className="text-type mr-2 mt-1">Security Type: Single URL + Additional Security</p>
                <Switch
                  size="small"
                  color="primary"
                  checked={activate}
                  onChange={handleChangeSecurity}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default SecurityType;
