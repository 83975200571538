import React, { useState, useEffect } from "react";
import ReactGa from "react-ga";
import Alert from "@material-ui/lab/Alert";
import { Button, CircularProgress } from "@material-ui/core";
import NewAPIModal from "../components/modals/NewAPIModal";
import WarningModal from '../components/modals/WarningModal';
import "../assets/styles/Home.scss";
import { connect } from "react-redux";
import ItemApi from "../components/ItemAPI";
import InfoUserModal from "../components/modals/InfoUserModal";
import PlusIcon from "../assets/images/Plus.svg";
import { userInformation } from "../actions/user.actions";
import { getAwsApiGateway } from "../actions/aws-api-gateway.actions";
import YoutubeEmbedd from "../components/YoutubeEmbed";
import {
  removeGateway,
  getSecureGateway,
  getSecureGateways,
  addSecureGateway,
  changeWarning
} from "../actions/secure-gateway.action";


const Home = ({
  done,
  error,
  loading,
  currentUser,
  gatewayError,
  awsApiGateway,
  removeGateway,
  createLoading,
  changeWarning,
  secureGateways,
  userInformation,
  addSecureGateway,
  getAwsApiGateway,
  getSecureGateway,
  getSecureGateways,
}) => {
  const [modal, showModal] = useState(false);
  const [modal2, showModal2] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showDelete2, setShowDelete2] = useState(false);
  const [gatewaySelected, setGatewaySelected] = useState('')

  useEffect(() => {
    getAwsApiGateway();
    ReactGa.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGa.pageview(`${window.location.pathname}${window.location.search}`);
  }, []);

  useEffect(() => {
    if (awsApiGateway) {
      getSecureGateways();
      setShowVideo(true);
    }
  }, [awsApiGateway]);

  const handleShowModal = () => {
    showModal2(!modal2);
    showModal(!modal);
  };

  const handleCloseModal = () => {
    showModal(!modal);
    setTimeout(() => { showModal2(!modal); }, 900)
  }

  const handleShowWarning = () => {
    if (done) {
      changeWarning(false)
    } else {
      changeWarning(true)
    }
  }

  const handleShowDelete = (gateway) => {
    setShowDelete(!showDelete);
    setTimeout(() => { setShowDelete2(!showDelete2)}, 900)
    setGatewaySelected(gateway);
  }

  return (
    <>
    {modal2 && (
        <NewAPIModal
        gatewayError={gatewayError}
        awsApiGateway={awsApiGateway}
        createLoading={createLoading}
        handleShowModal={handleShowModal}
        addSecureGateway={addSecureGateway}
        handleShowWarning={handleShowWarning}
        loading={loading}
        modal={modal}
        handleCloseModal={handleCloseModal}
      />
    )}


      {done && (
        <WarningModal
          handleShowWarning={handleShowWarning}
        />
      )}

      {!currentUser.survey && (
        <InfoUserModal userInformation={userInformation} />
      )}

      <div className="kor-page-container">

        <div className="kor-title-create">
          <p>API Connect</p>
          <Button
            id="connectAPI"
            color="primary"
            variant="contained"
            onClick={() => handleShowModal()}
          >
            <img src={PlusIcon} />
            Connect API
          </Button>
        </div>

        <div className="sub-title">
          <p>All Connections</p>
        </div>

        <div className="container-apis">
          {secureGateways && error && (
            <Alert severity="error" className="error-alert">Error: {error}</Alert>
          )}

          {loading ? (
            <div className="loader-apis">
              <CircularProgress />
              <p>Loading...</p>
            </div>
          ) : (
            <div>
              {showVideo && (!secureGateways || secureGateways.length < 1) && (
                <div className="blank-screen">
                  <YoutubeEmbedd />
                  <p>CONNECT YOUR FIRST API TO GET STARTED</p>
                </div>
              )}
            </div>
          )}

          {secureGateways &&
            secureGateways.length > 0 &&
            currentUser.survey &&
            secureGateways.map((gateway) => (
              <ItemApi
                key={gateway.id}
                gateway={gateway}
                currentUser={currentUser}
                removeGateway={removeGateway}
                handleShowDelete={handleShowDelete}
                getSecureGateway={getSecureGateway}
              />
            ))}
        </div>
      </div>
    </>
  );
};

const mapState = (state) => ({
  currentUser: state.user.user,
  done: state.secureGateway.done,
  error: state.secureGateway.error,
  loading: state.secureGateway.loading,
  gatewayError: state.secureGateway.gatewayError,
  createLoading: state.secureGateway.createLoading,
  awsApiGateway: state.awsApiGateway.awsApiGateway,
  secureGateways: state.secureGateway.secureGateways,
});

const mapDispatch = {
  changeWarning,
  removeGateway,
  userInformation,
  getAwsApiGateway,
  addSecureGateway,
  getSecureGateway,
  getSecureGateways,
};

export default connect(mapState, mapDispatch)(Home);
