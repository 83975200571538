const angularCodeComponent = (siteKey, URL, usagePlanToken) => {
  return `import { Component, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ngx-captcha';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-your',
  templateUrl: './your.component.html',
  styleUrls: ['./your.component.scss']
})
export class YourComponent implements OnInit {
  // This constant is required for ReCaptcha, which is used by KOR Connect 
  siteKey: string = '${siteKey}';

  constructor(private reCaptchaV3Service: ReCaptchaV3Service,
              private http: HttpClient) { }

  ngOnInit(): void {
  }

  // This function is an example of how to call your API through KOR Connect 
  requestApi () {
    this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
      /* We'll need this constant to make request */
      const timestamp = new Date().toUTCString();
      const headers = {
        // Place your headers here:
        token,
        timestamp,
        'x-api-key': '${usagePlanToken}'
      }
      // You need to append the path of the endpoint you are calling to the KOR Connect base URI 
      this.http.get('https://${URL}', { headers })
      .subscribe(response => {
        console.log(response);
      });
    });
  }

}`;
};

export default angularCodeComponent;
