// User
export const SIGNUP_OAUTH_BEGIN                = 'SIGNUP_OAUTH_BEGIN';
export const SIGNUP_OAUTH_SUCCESS              = 'SIGNUP_OAUTH_SUCCESS';
export const LOGIN_BEGIN    		               = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS  		               = 'LOGIN_SUCCESS';
export const LOGIN_ERROR    		               = 'LOGIN_ERROR';
export const SIGNUP_BEGIN   		               = 'SIGNUP_BEGIN';
export const SIGNUP_SUCCESS 		               = 'SIGNUP_SUCCESS';
export const SIGNUP_ERROR   		               = 'SIGNUP_ERROR';
export const LOGOUT_SUCCESS 		               = 'LOGOUT_SUCCESS';
export const NEW_PASSWORD_BEGIN                = 'NEW_PASSWORD_BEGIN';
export const NEW_PASSWORD_SUCCESS              = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_ERROR                = 'NEW_PASSWORD_ERROR';
export const UPDATE_USER_BEGIN                 = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS               = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_ERROR                 = 'UPDATE_USER_ERROR';
export const DELETE_USER_BEGIN                 = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS               = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR                 = 'DELETE_USER_ERROR';
export const RESET_PASSWORD_BEGIN              = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS            = 'RESET_PASSWORD_SUCCCESS';
export const RESET_PASSWORD_ERROR              = 'RESET_PASSWORD_ERROR';
export const USER_SURVEY_BEGIN                 = 'USER_SURVEY_BEGIN';
export const USER_SURVEY_SUCCESS               = 'USER_SURVEY_SUCCESS';
export const USER_SURVEY_ERROR                 = 'USER_SURVEY_ERROR';
export const SELECT_FRAMEWORK_BEGIN            = 'SELECT_FRAMEWORK_BEGIN';
export const SELECT_FRAMEWORK_SUCCESS          = 'SELECT_FRAMEWORK_SUCCESS';
export const SELECT_FRAMEWORK_ERROR            = 'SELECT_FRAMEWORK_ERROR'; 
// AWS API Gateway
export const GET_AWS_API_BEGIN                 = 'GET_AWS_API_BEGIN';
export const GET_AWS_API_SUCCESS               = 'GET_AWS_API_SUCCESS';
export const GET_AWS_API_ERROR                 = 'GET_AWS_API_ERROR';
// Secure Gateway
export const GET_SECURE_GATEWAY_BEGIN          = 'GET_SECURE_GATEWAY_BEGIN';
export const GET_SECURE_GATEWAY_SUCCESS        = 'GET_SECURE_GATEWAY_SUCCESS';
export const GET_SECURE_GATEWAY_ERROR          = 'GET_SECURE_GATEWAY_ERROR'; 
export const SHOW_SERCURE_GATEWAY_BEGIN        = 'SHOW_SERCURE_GATEWAY_BEGIN'; 
export const SHOW_SERCURE_GATEWAY_SUCCESS      = 'SHOW_SERCURE_GATEWAY_SUCCESS'; 
export const SHOW_SERCURE_GATEWAY_ERROR        = 'SHOW_SERCURE_GATEWAY_ERROR';
export const CREATE_SECURE_GATEWAY_BEGIN       = 'CREATE_SECURE_GATEWAY_BEGIN';
export const CREATE_SECURE_GATEWAY_SUCCESS     = 'CREATE_SECURE_GATEWAY_SUCCESS';
export const CREATE_SECURE_GATEWAY_ERROR       = 'CREATE_SECURE_GATEWAY_ERROR';
export const UPDATE_SECURE_GATEWAY_BEGIN       = 'UPDATE_SECURE_GATEWAY_BEGIN';
export const UPDATE_SECURE_GATEWAY_SUCCESS     = 'UPDATE_SECURE_GATEWAY_SUCCESS'; 
export const UPDATE_SECURE_GATEWAY_ERROR       = 'UPDATE_SECURE_GATEWAY_ERROR';
export const DELETE_SECURE_GATEWAY_BEGIN       = 'DELETE_SECURE_GATEWAY_BEGIN';
export const DELETE_SECURE_GATEWAY_SUCCESS     = 'DELETE_SECURE_GATEWAY_SUCCESS';
export const DELETE_SECURE_GATEWAY_ERROR       = 'DELETE_SECURE_GATEWAY_ERROR';
export const SHOW_WARNING_BEGIN                = 'SHOW_WARNING_BEGIN';
export const SHOW_WARNING_SUCCESS              = 'SHOW_WARNING_SUCCESS';
export const SHOW_WARNING_ERROR                = 'SHOW_WARNING_ERROR';

export const UPDATE_RATE_LIMITER_BEGIN          = 'UPDATE_RATE_LIMITER_BEGIN';
export const UPDATE_RATE_LIMITER_SUCCESS        =  'UPDATE_RATE_LIMITER_SUCCESS';
export const UPDATE_RATE_LIMITER_ERROR          = 'UPDATE_RATE_LIMITER_ERROR';

