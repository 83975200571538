import React from 'react';
import BaseURL from './BaseURL';
import HeaderInfo from './HeaderInfo';
import SecurityLevelBar from './SecurityLevelBar';

const SingleURL = (props) => {

  return (
    <>

      {props.secureGateway && (
        <>
          <p className="info-base-url-1"><span>Note:</span> when Testing Mode is turned on your IP address is automatically allowed through security. If you change IPs while you are on Testing Mode please switch to Production Mode then back to Testing Mode again to register the new IP.</p>
          <BaseURL
            secureGateway={props.secureGateway} 
          />
          <HeaderInfo
            secureGateway={props.secureGateway}
          />
        </>
      )}

      <div className="code-example">
        <p>Find example code <a href="https://gitlab.com/kor-comunity/simple-url-example/-/blob/main/index.html" target="_blank">here.</a></p>
      </div>

      <SecurityLevelBar 
        setSelectTab={props.setSelectTab}
        selectTab={props.selectTab}
      />
    </>
  );
}

export default SingleURL;
