import React, { useState, useEffect, useRef } from "react";
import "../../assets/styles/ModalKor.scss";
import Alert from "@material-ui/lab/Alert";
import closeIcon from "../../assets/images/closeIcon.svg";
import { Tooltip, Hidden, Button } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import InfoIconConnection from "../../assets/images/InfoIconConnection.svg";
import LoadingBoxNewConnection from '../LoadingBoxNewConnection';
import { useSpring, animated, useTransition } from 'react-spring'

const NewAPIModal = ({
  gatewayError,
  awsApiGateway,
  createLoading,
  handleShowModal,
  addSecureGateway,
  loading,
  modal,
  handleCloseModal }) => {

  const [name, setName] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [counter, setCounter] = useState(0);
  const [domains, setDomains] = useState([{ domain: "" }]);
  const [localhost, setLocalhost] = useState(false);
  const [targetKey, setTargetKey] = useState("");
  const [validator, setValidator] = useState(true);
  const [targetApiUrl, setTargetApiUrl] = useState("");
  const [port, setPort] = useState(false);
  const [open, setOpen] = useState(false);
  const [openInput2, setOpenInput2] = useState(false);
  const [openInput3, setOpenInput3] = useState(false);
  const [openInput4, setOpenInput4] = useState(false);
  const [openInput5, setOpenInput5] = useState(false);

  const handleClose = () => {
    setOpen(false);
  }

  const handleCloseInput2 = () => {
    setOpenInput2(false);
  }

  const handleCloseInput3 = () => {
    setOpenInput3(false);
  }

  const handleCloseInput4 = () => {
    setOpenInput4(false);
  }

  const handleCloseInput5 = () => {
    setOpenInput5(false);
  }

  const handlerAddSecureGateway = () => {
    addSecureGateway(
      domains,
      name,
      apiKey,
      awsApiGateway,
      targetKey,
      targetApiUrl
    );
  };

  const handleNewField = () => {
    if (!localhost) {
      setDomains((currenField) => [
        ...currenField,
        {
          domain: "",
        },
      ]);
      setValidator(true);
    }
  };

  const handleRemoveField = (index) => {
    if (domains.length > 1) {
      setDomains((currentField) =>
        currentField.filter((domain, i) => i !== index)
      );
      if (domains[index - 1].domain.length > 3) {
        setValidator(false);
      } else {
        setValidator(true);
      }
    }
  };

  const handleAddValue = (event, index) => {
    let text = event.target.value;
    let position = text.search(':');

    if (position != -1) {
      setPort(true);
      console.log(port);
    } else {
      setPort(false);
    }

    if (event.target.value === "localhost") {
      setLocalhost(true);
    } else {
      setLocalhost(false);
    }
    if (event.target.value.length > 3) {
      setValidator(false);
    } else {
      setValidator(true);
    }
    const domain = event.target.value;
    const field = [...domains];
    field[index].domain = domain;
  };


  const stylesAnimationEntry = useSpring({
    opacity: modal ? 1 : 0,
    config: {
      duration: 700,
      friction: 26
    }
  })

  const transitions = useTransition(modal, {
    items: modal,
    from: { x: 900 },
    enter: { x: 0 },
    leave: { x: 900 },
    reverse: modal,
    delay: 200,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      clamp: false,
      precision: 0.01,
      velocity: 0,
    }
  })

  useEffect(() => {
    setCounter(counter + 1);
    counter > 1 && !gatewayError && !createLoading &&
      handleShowModal();
  }, [createLoading]);

  return (
    <>
      <animated.div className="container-modal-kor">
        <animated.div
          className="background-opacity"
          style={stylesAnimationEntry}
          onClick={() => handleCloseModal()}
        >
        </animated.div>

        {transitions(({ x }, item) =>
          !item ? (
            <animated.div style={{
              x: x.to({ range: [0.0, 1.0], output: [0, 1] }),
              transform: x.to(y => `translate3d(900px,0,0)`)
            }} className="content-modal">
              <div className="title-modal">
                <div className="close-modal">
                  <img src={closeIcon} onClick={() => handleShowModal()} />
                  <p>
                    Create New <span>Connection</span>
                  </p>
                </div>
                <div className="caption-modal">
                  <p>Connect a new API for your account.</p>
                </div>

              </div>
              {localhost && (
                <div className="alert-ctn">
                  <Alert severity="error">You can't add localhost</Alert>
                </div>
              )}

              {port && (
                <div className="alert-ctn">
                  <Alert severity="error">A port number for your connection is not necessary.</Alert>
                </div>
              )}

              {gatewayError && (
                <div className="alert-ctn">
                  <Alert severity="error">Error: {gatewayError}</Alert>
                </div>
              )}

              {createLoading && (
                <LoadingBoxNewConnection />
              )}

              {!localhost && !gatewayError && !createLoading && (
                <div className="ctn-invisible"></div>
              )}

              <div className="content-modal-1">
                <div className="input-modal">
                  <div className="relative">
                    <p>Name</p>
                  </div>
                  <input
                    placeholder="Yahoo Finance"
                    onChange={(e) => setName(e.target.value)}
                    autoFocus={true}
                  />
                  <Tooltip
                    title="Add a name to identify your API"
                    tabIndex="-1"
                    className="desktop-tooltip-modal">
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add a name to identify your API"
                    className="mobile-tooltip-modal"
                    tabIndex="-1"
                    placement="left"
                    open={open}
                    onClose={handleClose}>
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpen(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>API Key</p>
                  </div>
                  <input
                    placeholder="b7fee807f8mshf1d5f49a0f323a1p15cacejsnxxxxxxxxxxxx"
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                  <Tooltip
                    title="Enter the API KEY that you want to use"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Enter the API KEY that you want to use"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput2}
                    onClose={handleCloseInput2}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput2(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>Header Name</p>
                  </div>
                  <input
                    placeholder="x-rapidapi-key"
                    onChange={(e) => setTargetKey(e.target.value)}
                  />
                  <Tooltip
                    title="This is the header name where your target’s API stores the API key as a value"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="This is the header name where your target’s API stores the API key as a value"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput3}
                    onClose={handleCloseInput3}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput3(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>API URL</p>
                  </div>
                  <input
                    placeholder="apidojo-yahoo-finance-v1.p.rapidapi.com"
                    onChange={(e) => setTargetApiUrl(e.target.value)}
                  />
                  <Tooltip
                    title="API URL is the path of your target API"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="API URL is the path of your target API"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput4}
                    onClose={handleCloseInput4}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput4(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <p className="title-allowed-domain">Allowed Domains</p>

                {domains.map((domain, index) => (
                  <div className="input-modal" key={index}>
                    <div className="relative">
                      <p>{`Domain ${index + 1}`}</p>
                      <Hidden mdUp>
                        {index !== 0 ? (
                          <span
                            className="pointer purple-text-absolute"
                            onClick={() => handleRemoveField(index)}
                          >
                            X
                          </span>
                        ) : (
                          <span className="white-text-absolute">X</span>
                        )}
                      </Hidden>
                    </div>
                    <input
                      placeholder={`korconnect${index + 1}.io`}
                      onChange={(e) => handleAddValue(e, index)}
                    />
                    <Tooltip
                      tabIndex="-1"
                      className="desktop-tooltip-modal"
                      title={`Enter the domain ${index + 1
                        } that you allow to make API requests`}
                    >
                      <IconButton aria-label="info">
                        <img src={InfoIconConnection} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      tabIndex="-1"
                      className="mobile-tooltip-modal"
                      placement="left"
                      open={openInput5}
                      onClose={handleCloseInput5}
                      title={`Enter the domain ${index + 1
                        } that you allow to make API requests`}
                    >
                      <IconButton aria-label="info">
                        <img src={InfoIconConnection} onClick={() => setOpenInput5(true)} />
                      </IconButton>
                    </Tooltip>
                    <Hidden mdDown>
                      {index !== 0 ? (
                        <span
                          className="pointer purple-text"
                          onClick={() => handleRemoveField(index)}
                        >
                          X
                        </span>
                      ) : (
                        <span className="white-text">X</span>
                      )}
                    </Hidden>
                  </div>
                ))}

                <div className="input-modal-add">
                  <p onClick={() => handleNewField()}>+ Add Domain</p>
                </div>

                <p className="note-api-localhost"><span>Note:</span> Localhost can not be entered into Allowed Domains. Localhost will be automatically allowed in Testing Mode after your connection is created. <a href="https://kor-comunity.gitlab.io/kor-connect/adir/TESTINGPRODUCTIONMODES.html" target="_blank">More info.</a></p>
              </div>

              <div className="buttons-action">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleCloseModal()}
                >
                  Back
                </Button>
                <Button
                  id="createConnection"
                  color="primary"
                  variant="contained"
                  onClick={() => handlerAddSecureGateway()}
                  disabled={
                    !name ||
                    apiKey.length < 2 ||
                    targetKey.length < 2 ||
                    targetApiUrl.length < 2 ||
                    localhost ||
                    port ||
                    loading
                  }
                >
                  Create Connection
                </Button>
              </div>
            </animated.div>
          ) : (
            <animated.div style={{
              x: x.to({ range: [0.0, 1.0], output: [0, 1] }),
            }} className="content-modal">
              <div className="title-modal">
                <div className="close-modal">
                <img src={closeIcon} onClick={() => handleCloseModal()} />
                  <p>
                    Create New <span>Connection</span>
                  </p>
                </div>
                <div className="caption-modal">
                  <p>Connect a new API for your account.</p>
                </div>

              </div>
              {localhost && (
                <div className="alert-ctn">
                  <Alert severity="error">You can't add localhost</Alert>
                </div>
              )}

              {port && (
                <div className="alert-ctn">
                  <Alert severity="error">A port number for your connection is not necessary.</Alert>
                </div>
              )}

              {gatewayError && (
                <div className="alert-ctn">
                  <Alert severity="error">Error: {gatewayError}</Alert>
                </div>
              )}

              {createLoading && (
                <LoadingBoxNewConnection />
              )}



              {!localhost && !gatewayError && !createLoading && (
                <div className="ctn-invisible"></div>
              )}

              <div className="content-modal-1">
                <div className="input-modal">
                  <div className="relative">
                    <p>Name</p>
                  </div>
                  <input
                    placeholder="Yahoo Finance"
                    onChange={(e) => setName(e.target.value)}
                    autoFocus={true}
                  />
                  <Tooltip
                    title="Add a name to identify your API"
                    tabIndex="-1"
                    className="desktop-tooltip-modal">
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Add a name to identify your API"
                    className="mobile-tooltip-modal"
                    tabIndex="-1"
                    placement="left"
                    open={open}
                    onClose={handleClose}>
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpen(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>API Key</p>
                  </div>
                  <input
                    placeholder="b7fee807f8mshf1d5f49a0f323a1p15cacejsnxxxxxxxxxxxx"
                    onChange={(e) => setApiKey(e.target.value)}
                  />
                  <Tooltip
                    title="Enter the API KEY that you want to use"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Enter the API KEY that you want to use"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput2}
                    onClose={handleCloseInput2}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput2(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>Header Name</p>
                  </div>
                  <input
                    placeholder="x-rapidapi-key"
                    onChange={(e) => setTargetKey(e.target.value)}
                  />
                  <Tooltip
                    title="This is the header name where your target’s API stores the API key as a value"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="This is the header name where your target’s API stores the API key as a value"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput3}
                    onClose={handleCloseInput3}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput3(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <div className="input-modal">
                  <div className="relative">
                    <p>API URL</p>
                  </div>
                  <input
                    placeholder="apidojo-yahoo-finance-v1.p.rapidapi.com"
                    onChange={(e) => setTargetApiUrl(e.target.value)}
                  />
                  <Tooltip
                    title="API URL is the path of your target API"
                    tabIndex="-1"
                    className="desktop-tooltip-modal"
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="API URL is the path of your target API"
                    tabIndex="-1"
                    className="mobile-tooltip-modal"
                    placement="left"
                    open={openInput4}
                    onClose={handleCloseInput4}
                  >
                    <IconButton aria-label="info">
                      <img src={InfoIconConnection} onClick={() => setOpenInput4(true)} />
                    </IconButton>
                  </Tooltip>
                  <span className="opacity-0">X</span>
                </div>
                <p className="title-allowed-domain">Allowed Domains</p>

                {domains.map((domain, index) => (
                  <div className="input-modal" key={index}>
                    <div className="relative">
                      <p>{`Domain ${index + 1}`}</p>
                      <Hidden mdUp>
                        {index !== 0 ? (
                          <span
                            className="pointer purple-text-absolute"
                            onClick={() => handleRemoveField(index)}
                          >
                            X
                          </span>
                        ) : (
                          <span className="white-text-absolute">X</span>
                        )}
                      </Hidden>
                    </div>
                    <input
                      placeholder={`korconnect${index + 1}.io`}
                      onChange={(e) => handleAddValue(e, index)}
                    />
                    <Tooltip
                      tabIndex="-1"
                      className="desktop-tooltip-modal"
                      title={`Enter the domain ${index + 1
                        } that you allow to make API requests`}
                    >
                      <IconButton aria-label="info">
                        <img src={InfoIconConnection} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      tabIndex="-1"
                      className="mobile-tooltip-modal"
                      placement="left"
                      open={openInput5}
                      onClose={handleCloseInput5}
                      title={`Enter the domain ${index + 1
                        } that you allow to make API requests`}
                    >
                      <IconButton aria-label="info">
                        <img src={InfoIconConnection} onClick={() => setOpenInput5(true)} />
                      </IconButton>
                    </Tooltip>
                    <Hidden mdDown>
                      {index !== 0 ? (
                        <span
                          className="pointer purple-text"
                          onClick={() => handleRemoveField(index)}
                        >
                          X
                        </span>
                      ) : (
                        <span className="white-text">X</span>
                      )}
                    </Hidden>
                  </div>
                ))}

                <div className="input-modal-add">
                  <p onClick={() => handleNewField()}>+ Add Domain</p>
                </div>

                <p className="note-api-localhost"><span>Note:</span> Localhost can not be entered into Allowed Domains. Localhost will be automatically allowed in Testing Mode after your connection is created. <a href="https://kor-comunity.gitlab.io/kor-connect/adir/TESTINGPRODUCTIONMODES.html" target="_blank">More info.</a></p>
              </div>

              <div className="buttons-action">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => handleCloseModal()}
                >
                  Back
                </Button>
                <Button
                  id="createConnection"
                  color="primary"
                  variant="contained"
                  onClick={() => handlerAddSecureGateway()}
                  disabled={
                    !name ||
                    apiKey.length < 2 ||
                    targetKey.length < 2 ||
                    targetApiUrl.length < 2 ||
                    localhost ||
                    port ||
                    loading
                  }
                >
                  Create Connection
                </Button>
              </div>
            </animated.div>
          )

        )}
      </animated.div>
    </>
  );
};

export default NewAPIModal;
