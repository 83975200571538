import { React, useState } from 'react';
import VueSnippet from './VueSnippet';
import HTMLSnippet from './HTMLSnippet';
import ReactSnippet from './ReactSnippet';
import AngularSnippet from './AngularSnippet';


const APISnippets = ({ secureGateway, selectFramework }) => {

  const [snippet, setSnippet] = useState('html');

  const handleSetSnippet = (selected) => {
    setSnippet(selected)
  }

  return (
    <>
      {snippet === 'html' && (
        <div className="snippet-type">
          <p className="active-tab" onClick={() => handleSetSnippet('html')} id="html">HTML</p>
          <p onClick={() => handleSetSnippet('react')} id="react">React</p>
          <p onClick={() => handleSetSnippet('vue')} id="vue">Vue</p>
          <p onClick={() => handleSetSnippet('angular')} id="angular">Angular</p>
        </div>
      )}

      {snippet === 'react' && (
        <div className="snippet-type">
          <p onClick={() => handleSetSnippet('html')} id="html">HTML</p>
          <p className="active-tab" onClick={() => handleSetSnippet('react')} id="react">React</p>
          <p onClick={() => handleSetSnippet('vue')} id="vue">Vue</p>
          <p onClick={() => handleSetSnippet('angular')} id="angular">Angular</p>
        </div>
      )}

      {snippet === 'vue' && (
        <div className="snippet-type">
          <p onClick={() => handleSetSnippet('html')} id="html">HTML</p>
          <p onClick={() => handleSetSnippet('react')} id="react">React</p>
          <p className="active-tab" onClick={() => handleSetSnippet('vue')} id="vue">Vue</p>
          <p onClick={() => handleSetSnippet('angular')} id="angular">Angular</p>
        </div>
      )}

      {snippet === 'angular' && (
        <div className="snippet-type">
          <p onClick={() => handleSetSnippet('html')} id="html">HTML</p>
          <p onClick={() => handleSetSnippet('react')} id="react">React</p>
          <p onClick={() => handleSetSnippet('vue')} id="vue">Vue</p>
          <p className="active-tab" onClick={() => handleSetSnippet('angular')} id="angular">Angular</p>
        </div>
      )}


      {snippet === 'html' && (
        <HTMLSnippet
          secureGateway={secureGateway}
          selectFramework={selectFramework}
        />
      )}

      {snippet === 'react' && (
        <ReactSnippet 
          secureGateway={secureGateway}
          selectFramework={selectFramework}
        />
      )}

      {snippet === 'vue' && (
        <VueSnippet
          secureGateway={secureGateway}
          selectFramework={selectFramework}
        />
      )}

      {snippet === 'angular' && (
        <AngularSnippet
          secureGateway={secureGateway}
          selectFramework={selectFramework}
        />
      )}
    
    </>
  )
}

export default APISnippets;
