import React from 'react';
import './index.css';
import App from './App';
import thunk from 'redux-thunk';
import reducer from './reducers';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { saveAuthToken, catchUnauth } from './middleware';
import { createStore, applyMiddleware, compose } from 'redux';
import { loadLastUser, validateSession } from './actions/user.actions';

const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunk, saveAuthToken, catchUnauth)
);

const store = createStore(reducer, enhancer);
store.dispatch(loadLastUser());
store.dispatch(validateSession());

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
