import React, { useEffect } from "react";
import ReactGa from 'react-ga';
import "../assets/styles/Login.scss";
import { connect } from "react-redux";
import routes from "../constants/routes";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import KORIcon from "../assets/images/KorIcon.svg";
import { newPassword } from "../actions/user.actions";
import NewPasswordForm from "../components/NewPasswordForm";
import KORBackground from "../assets/images/korBackground.svg";

const ResetPassword = withRouter(({ 
  error, 
  history, 
  message,
  newPassword
}) => {
  const handleBackLogin = () => {
    history.push(routes.LOGIN);
  };

  useEffect(() => {
    ReactGa.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGa.pageview(`${window.location.pathname}${window.location.search}`);
  }, []);

  useEffect(() => {
    if (message === 'Your password has been changed successfully.') { 
      history.push(routes.LOGIN);
    }
  }, [message]);

  return (
    <>
      <div className="app-container">
        <div className="app-header">
          <div className="kor-connect">
            <img src={KORIcon} />
          </div>
          <div>
            <Button onClick={() => handleBackLogin()}>Sign In</Button>
          </div>
        </div>

        <div className="main-page">
          <div className="image-kor-main">
            <img src={KORBackground} />
          </div>
          <div className="login-signup-form">
            <NewPasswordForm
              error={error}
              message={message}
              newPassword={newPassword}
            />                
          </div>
        </div>
      </div>
    </>
  );
});

const mapState = (state, props) => ({
  error: state.user.error,
  history: props.history,
  message: state.user.message,
});

const mapDispatch = {
  newPassword
};

export default connect(mapState, mapDispatch)(ResetPassword);
