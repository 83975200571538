import {
  loginUser,
  userSurvey,
  validToken,
  createUser,
  updateUser,
  deleteUser,
  passwordNew,
  chooseFramework,
  resetUserPassword,
} from "../api/users";

import {
  LOGIN_BEGIN,
  LOGIN_ERROR,
  SIGNUP_BEGIN,
  SIGNUP_ERROR,
  LOGIN_SUCCESS,
  SIGNUP_SUCCESS,
  LOGOUT_SUCCESS,
  USER_SURVEY_BEGIN,
  USER_SURVEY_ERROR,
  DELETE_USER_BEGIN,
  DELETE_USER_ERROR,
  UPDATE_USER_BEGIN,
  UPDATE_USER_ERROR,
  NEW_PASSWORD_BEGIN,
  NEW_PASSWORD_ERROR,
  SIGNUP_OAUTH_BEGIN,
  USER_SURVEY_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  NEW_PASSWORD_SUCCESS,
  SIGNUP_OAUTH_SUCCESS,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
  SELECT_FRAMEWORK_BEGIN,
  SELECT_FRAMEWORK_ERROR,
  SELECT_FRAMEWORK_SUCCESS,
} from "./types";

const URL = `${process.env.REACT_APP_BACKEND_URL}/auth`;

export const login = (username, password) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_BEGIN });
    loginUser(username, password)
      .then((response) => {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({ type: LOGIN_ERROR, error: error.response.data.errors });
      });
  };
};

export const loginWithOauth = (search) => {
  return (dispatch) => {
    dispatch({ type: LOGIN_BEGIN });
    const email = new URLSearchParams(search).get("email");
    const condition = new URLSearchParams(search).get("condition");
    if (email && condition) {
      dispatch({
        type: LOGIN_ERROR,
        error: `You already have an account with the email ${new URLSearchParams(
          search
        ).get("email")}, please sign up with a different email!`,
      });
    } else {
      const payload = {
        headers: {
          "access-token": new URLSearchParams(search).get("auth_token"),
          "cache-control": "max-age=0, private, must-revalidate",
          client: new URLSearchParams(search).get("client_id"),
          "content-type": "application/json; charset=utf-8",
          expiry: new URLSearchParams(search).get("expiry"),
          "token-type": "Bearer",
          uid: new URLSearchParams(search).get("uid"),
        },
        data: {
          data: {
            confirmed: true,
            email: new URLSearchParams(search).get("email"),
            country: new URLSearchParams(search).get("country"),
            job_function: null,
            name: new URLSearchParams(search).get("name"),
            onboarding: new URLSearchParams(search).get("onboarding"),
            provider: new URLSearchParams(search).get("provider"),
            subscription: new URLSearchParams(search).get("subscription"),
            uid: new URLSearchParams(search).get("uid"),
          },
        },
      };
      dispatch({
        type: LOGIN_SUCCESS,
        payload,
      });
    }
  };
};

export const signup = (username, name, password) => {
  return (dispatch) => {
    dispatch({ type: SIGNUP_BEGIN });
    createUser(username, name, password)
      .then((response) => {
        dispatch({
          type: SIGNUP_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        let errorMessage =  ''
        if(error.message === 'Request failed with status code 422'){
          errorMessage = 'The email you entered is already taken. '
        }
        dispatch({ type: SIGNUP_ERROR, error: errorMessage });
      });
  };
};

const openWindow = (provider) => {
  const uri = `${URL}/${provider}`;
  window.open(uri, "_self");
  return { type: SIGNUP_OAUTH_SUCCESS };
};

export const signupGithubOauth = () => {
  return (dispatch) => {
    dispatch({ type: SIGNUP_OAUTH_BEGIN });
    dispatch(openWindow("github"));
  };
};

export const signupGoogleOauth = () => {
  return (dispatch) => {
    dispatch({ type: SIGNUP_OAUTH_BEGIN });
    dispatch(openWindow("google_oauth2"));
  };
};

export const loadLastUser = () => {
  return (dispatch) => {
    const json = localStorage.getItem("currentUser");
    if (json && json !== "undefined") {
      const user = JSON.parse(json);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: user,
      });
    } else {
      dispatch(logout());
    }
  };
};

export const validateSession = () => {
  return (dispatch) => {
    const headers = localStorage.getItem("currentHeaders");
    if (!headers || headers === "undefined") {
      dispatch(logout());
    } else {
      validToken()
        .then((response) => {
          if (!response.data.success) {
            dispatch(logout());
          }
        })
        .catch(() => {
          dispatch(logout());
        });
    }
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({
      type: LOGOUT_SUCCESS,
    });
  };
};

export const updateName = (user) => {
  const data = {
    email: user.email,
    name: user.name,
  };
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    updateUser(data)
      .then((response) => {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_USER_ERROR,
          errorUpdateName: "Update name fail",
          error,
        });
      });
  };
};

export const updatePassword = (email, password) => {
  const data = {
    email,
    password,
  };
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_BEGIN });
    updateUser(data)
      .then(() => {
        dispatch({ type: UPDATE_USER_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_USER_ERROR,
          errorUpdatePassword: "Update password fail",
          error,
        });
      });
  };
};

export const removeUser = () => {
  return (dispatch) => {
    dispatch({ type: DELETE_USER_BEGIN });
    deleteUser()
      .then(() => {
        localStorage.clear();
        dispatch({ type: DELETE_USER_SUCCESS });
      })
      .catch(() => {
        localStorage.clear();
        dispatch({
          type: DELETE_USER_ERROR,
          error: "Your KOR account has been deleted",
        });
      });
  };
};

export const newPassword = (params, password, confirmPassword) => {
  return (dispatch) => {
    dispatch({ type: NEW_PASSWORD_BEGIN });
    passwordNew(params, password, confirmPassword)
      .then(() => {
        dispatch({ type: NEW_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: NEW_PASSWORD_ERROR,
          error: error.response.data.errors.full_messages[0],
        });
      });
  };
};

export const resetPassword = (email) => {
  return (dispatch) => {
    dispatch({ type: RESET_PASSWORD_BEGIN });
    resetUserPassword(email)
      .then(() => {
        dispatch({ type: RESET_PASSWORD_SUCCESS });
      })
      .catch((error) => {
        dispatch({
          type: RESET_PASSWORD_ERROR,
          error: error.response.data.errors[0],
        });
      });
  };
};

export const userInformation = (
  job_function,
  seniority_level,
  industry,
  hear_from_us,
  current_dislike,
  business_description
) => {
  return (dispatch) => {
    dispatch({ type: USER_SURVEY_BEGIN });
    const data = {
      job_function,
      seniority_level,
      industry,
      hear_from_us,
      current_dislike,
      business_description,
    };

    userSurvey(data)
      .then((response) => {
        const payload = response;
        dispatch({
          type: USER_SURVEY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: USER_SURVEY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const selectFramework = (framework) => {
  return (dispatch) => {
    const data = {
      framework,
    };
    dispatch({ type: SELECT_FRAMEWORK_BEGIN });
    chooseFramework(data)
      .then((response) => {
        dispatch({ type: SELECT_FRAMEWORK_SUCCESS });
      })
      .catch((error) => {
        dispatch({ 
          type: SELECT_FRAMEWORK_ERROR,
          error: error.response.data.error
        });
      });
  };
};
