import React, { useEffect } from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const LoadingBoxNewConnection = () => {
  const [progress, setProgress] = React.useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 1 : prevProgress + 1
      );
    }, 320);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const LinearProgressWithLabel = (props) => {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <div className="loading-mode loading-create-connection">
        <div className="progress-bar">
          <LinearProgressWithLabel value={progress} />
        </div>
        <div className="text-progress">
          <p>We are creating your connection.</p>
        </div>
      </div>
    </>
  );
};

export default LoadingBoxNewConnection;

