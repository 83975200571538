import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';

const TargetAPIDetails = ({ secureGateway, editSecureGateway, loading }) => {
  const [URL, setURL] = useState(secureGateway.targer_api_url);
  const [APIKey, setAPIKey] = useState("");
  const [showURL, setShowURL] = useState(false);
  const [targetKey, setTargetKey] = useState(secureGateway.target_key);
  const [showAPIKey, setShowAPIKey] = useState("");
  const [showTargetKey, setShowTargetKey] = useState(false);

  const handleUpdateURL = () => {
    const id = secureGateway.id;
    editSecureGateway(id, URL, 'api_url');
    setShowURL(false);
  }

  const handleUpdateTargetKey = () => {
    const id = secureGateway.id;
    editSecureGateway(id, targetKey, 'target_key');
    setShowTargetKey(false);
  }

  const handleUpdateAPIKey = () => {
    const id = secureGateway.id;
    editSecureGateway(id, APIKey, 'api_key');
    setShowAPIKey(false);
  }

  return (
    <>
      <div className="section-domains">
        <div className="title-target-api-info">
          <div></div>
          <p>Target API Info</p>
        </div>
        <div>
          {showURL ? (
            <div className="input-target-api">
              <input
                placeholder="Target API URL"
                value={URL}
                onChange={(e) => setURL(e.target.value)}
              />
              <Button
                id="detailUpdateURL"
                color="default"
                variant="outlined"
                className="ml-5"
                onClick={() => handleUpdateURL()}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="header-name-api-details">
              <div className="header-name-api-details_div">
                <p>API URL:</p>
                <p>{secureGateway.targer_api_url}</p>
              </div>
              <Button
                color="primary"
                variant="contained"
                className="ml-5"
                disabled={loading}
                onClick={() => setShowURL(true)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
        <div>
          {showTargetKey ? (
            <div className="input-target-api">
              <input
                placeholder="Header Name"
                value={targetKey}
                onChange={e => setTargetKey(e.target.value)}
              />
              <Button
                id="detailUpdateTarget"
                color="default"
                variant="outlined"
                className="ml-5"
                onClick={() => handleUpdateTargetKey()}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="header-name-api-details">
              <div className="header-name-api-details_div">
                <p>Header Name:</p>
                <p>{secureGateway.target_key}</p>
              </div>
              <Button
                color="primary"
                variant="contained"
                className="ml-5"
                disabled={loading}
                onClick={() => setShowTargetKey(true)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
        <div className="api-key">
          {showAPIKey ? (
            <div className="input-target-api api-key-container">
              <input
                placeholder="API KEY"
                value={APIKey}
                onChange={e => setAPIKey(e.target.value)}
              />
              <Button
                id="detailUpdateKey"
                color="default"
                variant="outlined"
                className="ml-5"
                onClick={() => handleUpdateAPIKey()}
              >
                Save
              </Button>
            </div>
          ) : (
            <div className="header-name-api-details">
              <div className="header-name-api-details_div api-key-container">
                <p>API KEY:</p>
                <p>****************</p>
              </div>
              <Button
                color="primary"
                variant="contained"
                className="ml-5"
                disabled={loading}
                onClick={() => setShowAPIKey(true)}
              >
                Edit
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapState = (state, props) => ({
  loading: state.secureGateway.loading,
  secureGateway: state.secureGateway.secureGateway,
  editSecureGateway: props.editSecureGateway
})

const mapDispatch = {

}

export default connect(mapState, mapDispatch)(TargetAPIDetails);
