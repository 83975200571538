import React, { useEffect, useState } from 'react';
import ReactGa from 'react-ga';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import '../assets/styles/SettingsPage.scss';
import { Button } from '@material-ui/core';
import trashButton from '../assets/images/TrashIcon.svg';
import WarningIcon from '../assets/images/WarningIcon.svg';
import { removeUser } from '../actions/user.actions';
import closeIcon from '../assets/images/closeIcon.svg';
import routes from "../constants/routes";

const SettingsPage = withRouter(({
    currentUser,
    removeUser,
    secureGateways,
    history
}) => {

    useEffect(() => {
        ReactGa.initialize(process.env.REACT_APP_TRACKING_ID);
        ReactGa.pageview(`${window.location.pathname}${window.location.search}`);
    }, []);

    const [showModal, setShowModal] = useState(false);
    const [selectedFeature, setSelectedFeature] = useState('General');

    const handleShowModal = () => {
        setShowModal(!showModal);
    }

    const handleSelectFeature = (feature) => {
        setSelectedFeature(feature);
        console.log(selectedFeature);
    }

    const handleGoHome = () => {
        history.push(`${routes.HOME}`);
    }



    return (
        <>
            {showModal && secureGateways && (
                <div>
                    {secureGateways.length > 0 ? (
                        <div className="modal-delete-account">
                            <div className="confirmation-modal">
                                <div className="close-icon" onClick={() => handleShowModal()}><img src={closeIcon} />
                                </div>
                                <div className="title-modal-account">
                                    <div className="title-icon-warning-1">
                                        <img src={WarningIcon} />
                                    </div>
                                    <div>
                                        <p>Delete Account</p>
                                    </div>
                                </div>

                                <div className="content-modal-text-1">
                                <p>You have to delete your connections first. <br /> You still have <b>{secureGateways.length}</b> connection(s). Please address this first.</p>
                    
                                </div>

                                
                            <div className="button-delete-account">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleShowModal()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled
                                >
                                    Delete
                                </Button>
                            </div>
                            </div>
                        </div>
                    ) : (
                        <div className="modal-delete-account">
                        <div className="confirmation-modal">
                            <div className="close-icon" onClick={() => handleShowModal()}><img src={closeIcon} />
                            </div>
                            <div className="title-modal-account">
                                <div className="title-icon-warning-1">
                                    <img src={WarningIcon} />
                                </div>
                                <div>
                                    <p>Delete Account</p>
                                </div>
                            </div>

                            <div className="content-modal-text-1">
                            <p>Are you sure you want to delete your account? <br /> This can not be undone.</p>
                            </div>

                            
                        <div className="button-delete-account">
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => handleShowModal()}
                            >
                                Cancel
                            </Button>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={removeUser}
                            >
                                Delete
                            </Button>
                        </div>
                        </div>
                    </div>
                    )}
                </div>
            )}


            <div className="kor-page-container">
                <div className="kor-title-create settings-api-connect">
                    <p><span onClick={handleGoHome}>Home</span> / Settings / <span className="name-api">{selectedFeature}</span></p>
                </div>

                <div className="settings-page">

                    {selectedFeature == 'General' ? (
                        <div className="menu-settings">
                            <p className="title-menu">Menu</p>
                            <p className="section-name active-tab" onClick={() => handleSelectFeature('General')}>General</p>
                            <p className="section-name" onClick={() => handleSelectFeature('Help')}>Help</p>
                        </div>
                    ) : (
                        <div className="menu-settings">
                            <p className="title-menu">Menu</p>
                            <p className="section-name" onClick={() => handleSelectFeature('General')}>General</p>
                            <p className="section-name active-tab" onClick={() => handleSelectFeature('Help')}>Help</p>
                        </div>
                    )}


                    {selectedFeature === 'General' ? (
                        <div className="content-settings">
                            <div className="delete-account-profile">
                                <p className="title-api">Profile</p>

                                <div className="card-user">
                                    <div className="user-avatar">
                                        <p>{currentUser.name.charAt(0).toUpperCase()}</p>
                                    </div>

                                    <div className="info-user">
                                        <p>{currentUser.name}</p>
                                        <p><span>Email:</span> {currentUser.email}</p>

                                    </div>
                                </div>

                                <p className="title-api-2">Delete Account</p>
                                <p>If you delete your account all of your resources will be deleted permanently, this cannot be undone.</p>
                                <Button onClick={() => handleShowModal()}>
                                    <img src={trashButton} />
                                    Delete my Kor Account
                                </Button>
                            </div>

                            <div className="copyright">
                                © 2021 KOR Inc. All rights reserved.
                                KOR is a registered trademark of FluxNetwork Inc. Terms, conditions, features, availability, pricing, fees, service and support options subject change without notice.
                            </div>
                        </div>
                    ) : (
                        <div className="content-settings">
                            <div className="delete-account-profile">
                                <p className="title-api">Additional Assistance</p>

                                <div className="card-user">
                                    <div className="user-avatar">
                                        <p>{currentUser.name.charAt(0).toUpperCase()}</p>
                                    </div>

                                    <div className="info-user">
                                        <p>{currentUser.name}</p>
                                        <p><span>Email:</span> {currentUser.email}</p>

                                    </div>
                                </div>

                                <p className="title-api-2">More Documentation</p>
                                <p>Find more documentation about KOR Connect here. Step by step guides to help you work more efficiently.</p>
                                <Button className='button-documentation'>
                                    <a href="https://kor-comunity.gitlab.io/kor-connect/"> See Documentation </a>
                                </Button>

                                <p className="title-api-2">Support</p>
                                <p>If you are unable to find what you are looking for. Please create a ticket. One of our engineers will contact you as soon as possible.</p>
                                <Button className='button-documentation-2'>
                                    <a href="mailto:support@getkor.io">support@getkor.io</a>
                                </Button>
                            </div>

                            <div className="copyright">
                                © 2021 KOR Inc. All rights reserved.
                                KOR is a registered trademark of FluxNetwork Inc. Terms, conditions, features, availability, pricing, fees, service and support options subject change without notice.
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
})

const mapState = (state) => ({
    currentUser: state.user.user,
    secureGateways: state.secureGateway.secureGateways
});

const mapDispatch = {
    removeUser
}

export default connect(
    mapState,
    mapDispatch
)(SettingsPage);
