import React, { useState, useEffect } from 'react';
import '../assets/styles/ConfirmAccount.scss';
import logoKor from '../assets/images/KorIcon.svg';
import confirmIllustration from '../assets/images/confirmAccount-01.svg';
import { Button, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import routes from "../constants/routes";

const ConfirmAccount =  withRouter(({
    history
}) => {

    const handleLogIn = () => {
        history.push(`${routes.LOGIN}`);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            handleLogIn();
        }, 9000);
        return () => clearTimeout(timer);
      }, []);


    return (
        <div className="confirm-account-container">
            <div className="nav-bar-page">
                <img src={logoKor} />
            </div>

            <div className="confirm-account-message">
                <img src={confirmIllustration} />
                <p>Thank you for confirming your email</p>
                <CircularProgress />
                <p>You will be redirected to KOR Connect or</p>
                <p>you can log in now</p>
                <Button
                    color="primary"
                    onClick={() => handleLogIn()}
                    variant="contained"
                >Log in now</Button>
            </div>
        </div>
    )
})

export default ConfirmAccount;


