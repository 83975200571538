const reactCodeIndex = (siteKey) => {
  return `import {
  GoogleReCaptchaProvider,
} from 'react-google-recaptcha-v3';


ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="${siteKey}">
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById('root')
);`;
};

export default reactCodeIndex;
