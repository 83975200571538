import React, { useState } from 'react';
import { Snackbar } from '@material-ui/core';

const BaseURL = (props) => {
    const baseURL = 'https://' + props.secureGateway.secure_url_endpoint + '/';
    const [ open, setOpen ] = useState(false);

    const handleCopyURL = () => {
        navigator.clipboard.writeText(baseURL);
        setOpen(true);
    }
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };
    
    return (
        <div className="type-ctn base-url-ctn">
            <div className="secure-base-url-ctn">
                <div className="div-opacity-details"></div>
                <p>New Secure Base URL</p>
            </div>

            <div className="url-secure-api">
                <p>https://{props.secureGateway.secure_url_endpoint}/</p>
            </div>

            <div className="documentation-url">
                <p>Append the path of your endpoint to this secure base url.</p>
                <p onClick={() => handleCopyURL()}>Copy URL</p>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={2000}
                message="The Base URL has been copied."
            />
        </div>
    )
}

export default BaseURL;
