import React, { useState } from "react";
import { Button } from "@material-ui/core";
import "../../assets/styles/InfoUserModal.scss";
import industry from "../../constants/industry";
import heardFromUs from "../../constants/heardFromUs";
import jobFunctions from "../../constants/jobFunctions";
import seniorityLevel from "../../constants/seniorityLevel";

const InfoUserModal = ({ userInformation }) => {
  const [level, setLevel] = useState("");
  const [heardUs, setHeardUs] = useState("");
  const [dislike, setDislike] = useState("");
  const [description, setDescription] = useState("");
  const [jobFunction, setJobFunction] = useState("");
  const [industryText, setIndustryText] = useState("");

  return (
    <div className="modal-container">
      <div className="modal-info-user">
        <div className="user-close-modal">
          <p>User Survey</p>
        </div>

        <p className="subtitle-p">
          <p>Please complete this form</p>
        </p>
        <div className="input-container">
          <div className="title-ctn">
            <p>Job function</p>
          </div>
          <select
            placeholder="Job function"
            defaultValue={"DEFAULT"}
            onChange={(e) => setJobFunction(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select your job function
            </option>
            {jobFunctions.map((job) => (
              <option key={job.id}>{job.name}</option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <div className="title-ctn">
            <p>Years as a developer?</p>
          </div>
          <select
            placeholder="Siniority Level"
            defaultValue={"DEFAULT"}
            onChange={(e) => setLevel(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select the number of years you have been a developer
            </option>
            {seniorityLevel.map((level) => (
              <option key={level.id}>{level.name}</option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <div className="title-ctn">
            <p>Industry</p>
          </div>
          <select
            placeholder="What industry do you work in?"
            defaultValue={"DEFAULT"}
            onChange={(e) => setIndustryText(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select the industry you work in
            </option>
            {industry.map((industry) => (
              <option key={industry.id}>{industry.name}</option>
            ))}
          </select>
        </div>
        <div className="input-container">
          <div className="title-ctn">
            <p>How did you hear about us?</p>
          </div>
          <select
            placeholder="How did you hear about us"
            defaultValue={"DEFAULT"}
            onChange={(e) => setHeardUs(e.target.value)}
          >
            <option disabled value="DEFAULT">
              Select how you heard about us?
            </option>
            {heardFromUs.map((heard) => (
              <option key={heard.id}>{heard.name}</option>
            ))}
          </select>
        </div>
        <div className="info-input">
          <div className="input-container">
            <div className="title-ctn">
              <p>Currently dislike</p>
            </div>
            <input
              placeholder="What do you dislike about your current solution?"
              onChange={(e) => setDislike(e.target.value)}
            />
          </div>
          <div className="input-container">
            <div className="title-ctn">
              <p>Over the next year</p>
            </div>
            <input
              placeholder="Where do you want you or your business to be in a year?"
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
        </div>

        <div className="buttons-close">
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              userInformation(
                jobFunction,
                level,
                industryText,
                heardUs,
                dislike,
                description
              )
            }
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InfoUserModal;
