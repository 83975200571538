import { combineReducers } from 'redux';
import user from './user';
import secureGateway from './secureGateway';
import awsApiGateway from './awsApiGateway';

export default combineReducers({
  user,
  secureGateway,
  awsApiGateway,
});
