import React from 'react';
import '../../assets/styles/ModalKor.scss';
import closeIcon from '../../assets/images/closeIcon.svg';
import WarningIcon from '../../assets/images/WarningIcon.svg';
import { Grid, Button } from "@material-ui/core";
import TrashIcon from '../../assets/images/TrashIcon.svg';
import { useSpring, animated, useTransition } from 'react-spring'

const DeleteConnectionModal = ({ 
    handleDeleteConnection, 
    setShowDelete, 
    showDelete, 
    handleCloseModalDelete }) => {
        
    const stylesAnimationEntry = useSpring({
        opacity: showDelete ? 1 : 0,
        config: {
            duration: 700,
            friction: 26
        }
    })

    const transitions = useTransition(showDelete, {
        items: showDelete,
        from: { y: -900 },
        enter: { y: 0 },
        leave: { y: -900 },
        delay: 40,
        config: {
            mass: 1,
            tension: 170,
            friction: 26,
            clamp: false,
            precision: 0.01,
            velocity: 0.5,
        }
    })

    return (
        <>
            <div className="container-modal-kor modal-warning">
                
                <animated.div 
                    className="background-opacity"  
                    style={stylesAnimationEntry}>
                </animated.div>

                {transitions(({ y }, item) =>
                    item ? (
                        <animated.div style={{
                            y: y.to({ range: [0.0, 1.0], output: [0, 1] }),
                            transform: y.to(y => `translate3d(0,0px,0)`)
                        }} className="content-modal-warning">
                            <div className="close-icon-warning">
                                <img src={closeIcon}
                                    onClick={() => handleCloseModalDelete()}
                                />
                            </div>

                            <div className="content-warning">
                                <div className="title-modal-warning">
                                    <div className="icon-info-title">
                                        <img src={WarningIcon} />
                                    </div>
                                    <p>Delete Connection</p>
                                </div>
                                <div className="warning-icon undone-text">
                                    <p>Are you sure you want to delete this connection? This can not be undone and all of your settings for this connection will be deleted.</p>
                                </div>
                            </div>

                            <div className="button-got-it button-delete-connection">
                                <div>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleCloseModalDelete()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleDeleteConnection()}
                                >
                                    Delete
                                </Button>
                                </div>
                            </div>
                        </animated.div>
                    ) : (
                        <animated.div style={{
                            y: y.to({ range: [0.0, 1.0], output: [0, 1] }),
                            transform: y.to(y => `translate3d(0,-900px,0)`)
                          }} className="content-modal-warning">
                            <div className="close-icon-warning">
                                <img src={closeIcon}
                                    onClick={() => setShowDelete(false)}
                                />
                            </div>

                            <div className="content-warning">
                                <div className="title-modal-warning">
                                    <p>Are you sure you want to delete this connection?</p>
                                </div>
                                <div className="warning-icon undone-text">
                                    <p>This can not be undone.</p>
                                </div>
                            </div>

                            <div className="button-got-it button-delete-connection">
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => handleDeleteConnection()}
                                >
                                    <img src={TrashIcon} />
                                    Delete
                                </Button>
                            </div>
                        </animated.div>
                    ))}
            </div>
        </>
    )
}

export default DeleteConnectionModal;
