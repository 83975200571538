import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { logout } from '../actions/user.actions';
import '../assets/styles/Navbar.scss';
import LogoutIcon from '../assets/images/LogoutIcon.svg';
import KORIcon from '../assets/images/KorIcon.svg';
import LifebuoyIcon from '../assets/images/LifebuoyIcon.svg';
import SettingsIcon from '../assets/images/SettingsIcon.svg';
import MenuIcon from '../assets/images/MenuIcon.svg';
import { withRouter } from 'react-router-dom';
import routes from '../constants/routes';
import { Tooltip } from '@material-ui/core';
import { Forum } from '@material-ui/icons';
import ForumIcon from '../assets/images/ForumIcon.svg';
import MenuResponsive from '../components/modals/MenuResponsive';


const Navbar = withRouter(
  ({
    logout,
    currentUser,
    history
  }) => {

    const [showMenu, setShowMenu] = useState(false);

    const handleViewSettings = () => {
      history.push(`${routes.SETTINGS}`);
    }

    const handleGoHome = () => {
      history.push(`${routes.HOME}`)
    }

    const handleShowMenu = () => {
      setShowMenu(!showMenu)
    }

    const handleLogOut = () => {
      logout()
    }


    return (
      <div className="kor-navbar">
        {showMenu && (
          <MenuResponsive
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            handleShowMenu={handleShowMenu}
            handleLogOut={handleLogOut}
          />
        )}

        <div className="kor-connect" id="home" onClick={handleGoHome}>
          <img src={KORIcon} />
          <p></p>
        </div>
        <div className="avatar-name-kor menu-non-responsive">
          <div className="avatar-container">
            {currentUser.name.charAt(0).toUpperCase()}
          </div>
          <span>{currentUser.name}</span>
          <div className='icon-navbar'>
            <Tooltip title="Settings">
              <img
                id="settings"
                src={SettingsIcon}
                onClick={() => handleViewSettings()}
              />
            </Tooltip>
          </div>
          <div className='icon-navbar last-item documentation-link'>
            <Tooltip title="Help">
              <a href="https://kor-comunity.gitlab.io/kor-connect/" id="navDocs" target="_blank">
                <img src={LifebuoyIcon}
                />
              </a>
            </Tooltip>
          </div>
          <div className='icon-navbar last-item community-link'>
            <Tooltip title="Community">
              <a href="https://discourse.korconnect.io/" id="navCommunity" target="_blank">
                <img
                  id="chat"
                  src={ForumIcon}
                />
              </a>
            </Tooltip>
          </div>
          <div className='icon-navbar last-item'>
            <Tooltip title="Sign out">
              <img
                id="logout"
                src={LogoutIcon}
                onClick={() => logout()}
              />
            </Tooltip>
          </div>
        </div>

        <div className="avatar-name-kor menu-responsive" onClick={() => handleShowMenu()}>
          <div className="avatar-container">
            {currentUser.name.charAt(0).toUpperCase()}
          </div>
          <span>{currentUser.name}</span>
          <div>
            <img src={MenuIcon} />
          </div>
        </div>

      </div>
    );
  })

const mapState = (state) => ({
  currentUser: state.user.user
});

const mapDispatch = {
  logout
};

export default connect(
  mapState,
  mapDispatch
)(Navbar);
