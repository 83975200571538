const codeString = (apiGetwayURL, reCatchaAKey, usagePlanToken) => {
  return `<html>
      <head>
        <title>simple page</title>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.25.0/axios.min.js" integrity="sha512-/Q6t3CASm04EliI1QyIDAA/nDo9R8FQ/BULoUFyN4n/BDdyIxeH7u++Z+eobdmr11gG5D/6nPFyDlnisDwhpYA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
         <script src="https://www.google.com/recaptcha/api.js" async defer></script>
         <script>
           // This function is an example of how to call your API through KOR Connect
           function onSubmit(token) {
             // We'll need this constant to make request
             const timestamp = new Date().toUTCString();
             // You need to append the path of the endpoint you are calling to the KOR Connect base URI 
             axios.get("https://${apiGetwayURL}", { headers: { /* Place your headers here: */ token, timestamp, "x-api-key": "${usagePlanToken}" } })
                .then(response => {
                  console.log(response)
                })
                .catch(error => {
                  console.log(error)
                })
           }
         </script>
         <style>
           /* This is used to hide your Recaptcha badge (please read Recaptcha’s TOS) */
           .grecaptcha-badge { visibility: collapse !important; }
         </style>
      </head>
      <body>
        <form id='demo-form' action="?" method="POST">
          <!-- Oops.... avoid the name="submit" below -->
          <button class="g-recaptcha" data-sitekey="${reCatchaAKey}" data-callback='onSubmit'>Submit</button>
          <br/>
        </form>
      </body>
    </html> `;
};

export default codeString;
