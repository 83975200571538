import React, { useState } from "react";
import { Button } from "@material-ui/core";

const ResetPasswordForm = ({ error, message, resetPassword, setShowResetPassword }) => {
  const [email, setEmail] = useState("");

  const handleResetPassword = () => {
    resetPassword(email);
    setShowResetPassword(false);
  }

  return (
    <div className="registrer-form">
      <h1>
       Reset Password for <br></br> <span>KOR Connect</span>
      </h1>
      <p className="muted-text">Enter your email to confirm your KOR connect account</p>
      <div className="form-container">
        <form>
          <div className="error-login">{error && <span>{error}</span>}</div>
          {message && <div className="success-login"><span>{message}</span></div>}
          <div className="input-container">
            <p>Email</p>
            <input
              placeholder="youremail@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <Button
            id="resetPass"
            color="primary"
            variant="contained"
            onClick={() => handleResetPassword()}
          >
            Reset 
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
