import React from "react";
import closeIcon from '../../assets/images/closeIcon.svg';
import { Button, Avatar } from "@material-ui/core";
import check1 from '../../assets/images/check1.svg';
import InfoIcon from '../../assets/images/InfoIcon.svg';

const WarningModal = ({ handleShowWarning }) => {
    return (
        <>
            <div
                className="container-modal-kor modal-warning"
            >
                <div className="background-opacity">
                </div>
                <div className="content-modal-warning">
                    <div className="close-icon-warning">
                        <img src={closeIcon} 
                            onClick={() =>  handleShowWarning()}
                        />
                    </div>

                    <div className="content-warning">
                        <div className="title-modal-warning">
                            <Avatar className="mr-2">
                                <img src={check1} />
                            </Avatar>
                            <p>Connection created succesfully</p>
                        </div>
                        <div className="warning-icon">
                            <div className="icon-info-warning">
                                <img src={InfoIcon} />
                            </div>
                            <p> Your connection was created in <span>testing mode</span>, remember to change it to <span>production mode</span> when you are ready to deploy your application. 
                                For more information go to <span><a href="https://kor-comunity.gitlab.io/kor-connect/adir/TESTINGPRODUCTIONMODES.html" target="_blank">KOR Enviroments Documentation.</a></span>
                            </p>
                        </div>

                    </div>

                    <div className="button-got-it">
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={() =>  handleShowWarning()}
                        >
                            Got it
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WarningModal;
