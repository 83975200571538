const angularCodeIndex = `<!doctype html>
<html lang="en">
<head>
  <meta charset="utf-8">
  <title>AngularApp</title>
  <base href="/">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="icon" type="image/x-icon" href="favicon.ico">
  <style>
   /* This is used to hide your Recaptcha badge (please read Recaptcha’s TOS) */
   .grecaptcha-badge { visibility: collapse !important; }
  </style>
</head>
<body>
  <app-root></app-root>
</body>
</html>`;

export default angularCodeIndex;
