import axios from 'axios';

const URL = process.env.REACT_APP_BACKEND_URL;

export const fetchAwsApiGateway = () => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));

  return axios.get(`${URL}/aws/aws_api_gateway/1`, { headers: authToken });
}

export const newAwsApiGateway = () => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));

  const data = {
    authorizer: 'headers',
    description: 'KOR SECURE GATEWAY'
  }

  return axios.post(`${URL}/aws/aws_api_gateway`, data, { headers: authToken });
}

export const createResource = (data) => {
  const authToken = JSON.parse(localStorage.getItem('currentHeaders'));

  return axios.post(`${URL}/aws/create_resource`, data, { headers: authToken });
}
