import { createResource } from "../api/aws/aws-api-gateway";
import {
  showGateway,
  fetchGateways,
  createGateway,
  updateGateway,
  deleteGateway,
  changeRateLimit
} from "../api/secure-gateway";
import {
  GET_SECURE_GATEWAY_BEGIN,
  GET_SECURE_GATEWAY_SUCCESS,
  GET_SECURE_GATEWAY_ERROR,
  SHOW_SERCURE_GATEWAY_BEGIN,
  SHOW_SERCURE_GATEWAY_SUCCESS,
  SHOW_SERCURE_GATEWAY_ERROR,
  CREATE_SECURE_GATEWAY_BEGIN,
  CREATE_SECURE_GATEWAY_SUCCESS,
  CREATE_SECURE_GATEWAY_ERROR,
  UPDATE_SECURE_GATEWAY_BEGIN,
  UPDATE_SECURE_GATEWAY_SUCCESS,
  UPDATE_SECURE_GATEWAY_ERROR,
  DELETE_SECURE_GATEWAY_BEGIN,
  DELETE_SECURE_GATEWAY_SUCCESS,
  DELETE_SECURE_GATEWAY_ERROR,
  SHOW_WARNING_BEGIN,
  SHOW_WARNING_SUCCESS,
  SHOW_WARNING_ERROR,
  UPDATE_RATE_LIMITER_BEGIN,
  UPDATE_RATE_LIMITER_SUCCESS,
  UPDATE_RATE_LIMITER_ERROR
} from "./types";

export const changeWarning = (doneStatus) => {
  return (dispatch) => {
    if(doneStatus){
      dispatch({
        type: SHOW_WARNING_BEGIN,
        done: true
      });
    }else{
      dispatch({
        type: SHOW_WARNING_BEGIN,
        done: false
      });
    }
  }
}

export const getSecureGateways = () => {
  return (dispatch) => {
    dispatch({ type: GET_SECURE_GATEWAY_BEGIN });
    fetchGateways()
      .then((response) => {
        const payload = response.data.secure_gateways;
        let data = payload;
 
        data.sort((a,b) => {
          let dateA = new Date(a.created_at);
          let dateB = new Date(b.created_at);
          return dateB - dateA;
        })

        dispatch({
          type: GET_SECURE_GATEWAY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const getSecureGateway = (id) => {
  return (dispatch) => {
    dispatch({ type: SHOW_SERCURE_GATEWAY_BEGIN });
    showGateway(id)
      .then((response) => {
        const payload = response.data.secure_gateway;
        dispatch({
          type: SHOW_SERCURE_GATEWAY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: SHOW_SERCURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const addSecureGateway = (
  obj,
  name,
  api_key,
  apiGateway,
  target_key,
  target_api_url,
) => {
  return (dispatch) => {
    dispatch({ type: CREATE_SECURE_GATEWAY_BEGIN });
    const arr = [];
    obj.forEach((domain) => {
      if (domain.domain !== "")
        arr.push(domain.domain);
    });
    const domains = arr.join(",").toString();
    const data = {
      name: name.replace(/[^A-Z0-9]/gi, "-"),
      domains,
      api_key,
      target_key,
      target_api_url,
      project_id: apiGateway.id,
      connection_mode: 'testing'
    };

    createGateway(data)
      .then((response) => {
        const data = {
          id: response.data.secure_gateway_id,
        };
        createResource(data)
          .then((response) => {
            const payload = response.data.historical;
            let data = payload;
     
            data.sort((a,b) => {
              let dateA = new Date(a.created_at);
              let dateB = new Date(b.created_at);
              return dateB - dateA;
            })

            dispatch({
              type: CREATE_SECURE_GATEWAY_SUCCESS,
              payload,
              done: true
            });
          })
          .catch((error) => {
            dispatch({
              type: CREATE_SECURE_GATEWAY_ERROR,
              error: error.response.data.error,
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: CREATE_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const updateDomains = (id, domains) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SECURE_GATEWAY_BEGIN, typeUpdate: 'domains' });
    const parameter = domains.join(",").toString();
    const update_param = "origins";
    const data = {
      parameter,
      update_param,
    };

    updateGateway(id, data)
      .then((response) => {
        const payload = response.data;

        dispatch({
          type: UPDATE_SECURE_GATEWAY_SUCCESS,
          payload,
          typeUpdate: 'domains'
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const updateEnvironment = (id, parameter) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SECURE_GATEWAY_BEGIN, typeUpdate: 'enviroment' });
    const update_param = "connection_mode";
    const data = {
      parameter,
      update_param,
    };

    updateGateway(id, data)
      .then((response) => {
        const payload = response.data;
        dispatch({
          type: UPDATE_SECURE_GATEWAY_SUCCESS,
          payload,
          typeUpdate: 'enviroment'
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const editSecureGateway = (id, parameter, update_param) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SECURE_GATEWAY_BEGIN, typeUpdate: 'security' });

    const data = {
      parameter,
      update_param,
    };

    updateGateway(id, data)
      .then((response) => {
        const payload = response.data;

        dispatch({
          type: UPDATE_SECURE_GATEWAY_SUCCESS,
          payload,
          typeUpdate: 'security'
        });
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const removeGateway = (secureGateway) => {
  return (dispatch) => {
    dispatch({ type: DELETE_SECURE_GATEWAY_BEGIN });
    deleteGateway(secureGateway.id)
      .then((response) => {
          const payload = response.data.historical;
          let data = payload;
     
          data.sort((a,b) => {
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })
            
        dispatch({
          type: DELETE_SECURE_GATEWAY_SUCCESS,
          payload,
        });
      })
      .catch((error) => {
        dispatch({
          type: DELETE_SECURE_GATEWAY_ERROR,
          error: error.response.data.error,
        });
      });
  };
};

export const updateRateLimit = (id, parameter) => {
  return(dispatch) => {
    dispatch({
      type: UPDATE_RATE_LIMITER_BEGIN
    });
    const update_param = "rate_limit";
    const data = {
      parameter,
      update_param
    }
    updateGateway(id, data)
      .then((response) => {
        const payload = response.data.historical;
        dispatch({
          type: UPDATE_RATE_LIMITER_SUCCESS,
          payload
        })
      })
      .catch((error) => {
        dispatch({
          type: UPDATE_RATE_LIMITER_ERROR,
          error: error.response.data.error
        })
      })
  }
}