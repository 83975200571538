import produce from 'immer';
import { GET_AWS_API_BEGIN, GET_AWS_API_SUCCESS, GET_AWS_API_ERROR } from '../actions/types';

const initialState = {
  error: null,
  loading: false,
  awsApiGateway: null
};

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_AWS_API_BEGIN:
      draft.error = null;
      draft.loading = true;
      draft.awsApiGateway = null;
      return;
    case GET_AWS_API_SUCCESS:
      draft.error = null;
      draft.loading = false;
      draft.awsApiGateway = action.payload;
      return;
    case GET_AWS_API_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    default:
      return;
  }
} ,initialState);

export default reducer;
