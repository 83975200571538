const seniorityLevel = [
    { name: '1', code: '01' },
    { name: '2', code: '02'},
    { name: '3', code: '03' },
    { name: '4', code: '04' },
    { name: '5', code: '05' },
    { name: '6', code: '06' },
    { name: '7', code: '07' },
    { name: '+', code: '08' }
];

export default seniorityLevel;
