const jobFunctions = [
    { name: 'Data Scientist', code: '01' },
    { name: 'DevOps', code: '02'},
    { name: 'Data Engineer', code: '03' },
    { name: 'Data Architect', code: '04' },
    { name: 'Database Admin', code: '05' },
    { name: 'Frontend Developer', code: '06' },
    { name: 'Backend Developer', code: '07' },
    { name: 'IT manager/executive', code: '08' },
    { name: 'Web designer', code: '09' },
    { name: 'Business manager/executive', code: '10' },
    { name: 'Jamstack developer', code: '11' },
    { name: 'Other', code: '12' }
];

export default jobFunctions;
