import React from 'react';

const YoutubeEmbedd = () => {
  return (
    <>
        <iframe 
            width="650" 
            height="455" 
            src="https://www.youtube.com/embed/djmF0lYWHbA" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>                
        </iframe>
    </>
  );
}

export default YoutubeEmbedd;
