import React from "react";
import Grid from '@material-ui/core/Grid';
import SecurityLevelBar from './SecurityLevelBar'
import switchImage from '../assets/images/switchImage.JPG';

const SnippetWarning = (props) => {
  return (
    <>
      <div className="type-ctn warning-additional-ctn">

        <div className="warning-icon-additional">
          <p>!</p>
        </div>

        <div className="info-additional">
            <p>Welcome to the Additional Security section.</p>
            <p>You can increase the security of your API connection if you feel that it is necessary. 
            <br></br>With a bit of additional work you can activate an attestation layer delivered via Recaptcha and a more robust origin validation process that is added to the Single URL Security. </p>
            <br></br>
            <p>To activate the Additional Security you must turn the <span>Single URL + Additional Security</span> switch on. </p>
            <img className="img-switch" src={switchImage} />
            <br></br>
            <p>This will activate the components associated with the additional security. <br></br>In order for your connection 
            to work with the Additional Security Type activated you must add the correct Snippets of code (below) 
            to the corresponding framework that your project uses. For more detailed instructions and examples <a href="https://kor-comunity.gitlab.io/kor-connect/adir/SECURITYTYPES.html" target="_blank"> click here.</a></p>
        </div>
    </div>

    <SecurityLevelBar
      selectTab={props.selectTab}
    />
    </>
  );
};

export default SnippetWarning;
