import React, { useState, useEffect } from "react";
import Moment from "moment";
import PlusIcon from "../assets/images/Plus.svg";
import AddDomainForm from "./AddDomainForm";
import { Button } from "@material-ui/core";
import trashIcon from "../assets/images/TrashIcon.svg";
import TargetAPIDetails from "./TargetAPIDetails";
import AddDomainModal from '../components/modals/AddDomainModal';
import DeleteConnectionModal from '../components/modals/DeleteConnectionModal';


const APIDetails = (props) => {
  const [domains, setDomains] = useState([]);
  const [switchEnv, setSwitchEnv] = useState(false);
  const [showDomainsFields, setShowDomainsFields] = useState(false);
  const [showAddDomainModal, setShowAddDomainModal] = useState(false);
  const [modal2, showModal2] = useState(false);


  const handleShowAddDomainModal = () => {
    setDomains(props.secureGateway.allowed_origins.split(","));
    setShowAddDomainModal(!showAddDomainModal);
    showModal2(!modal2);
  }

  const handleCloseModal = () => {
    setShowAddDomainModal(!showAddDomainModal);
    setTimeout(() => { showModal2(!modal2); }, 900)
  }


  useEffect(() => {
    props.secureGateway.connection_mode === 'testing' ?
      setSwitchEnv(false) :
      setSwitchEnv(true);
  }, [props.secureGateway]);

  return (
    <>

      {modal2 && (
        <AddDomainModal
          domains={domains}
          setDomains={setDomains}
          secureGateway={props.secureGateway}
          updateDomains={props.updateDomains}
          setShowDomainsFields={setShowDomainsFields}
          handleShowAddDomainModal={handleShowAddDomainModal}
          modal={showAddDomainModal}
          handleCloseModal={handleCloseModal}
          typeUpdate={props.typeUpdate}
          setTypeUpdate={props.setTypeUpdate}
        />
      )}

      {props.showDelete && (
        <DeleteConnectionModal
          handleShowDelete={props.handleShowDelete}
          handleDeleteConnection={props.handleDeleteConnection}
          setShowDelete={props.setShowDelete}
          showDelete={props.showDelete}
          handleCloseModalDelete={props.handleCloseModalDelete}
        />
      )}

     
      <div className="details-page-container">
        <div className="type-ctn target-api-ctn">
          <TargetAPIDetails
            secureGateway={props.secureGateway}
            editSecureGateway={props.editSecureGateway}
            loading={props.loading}
          />
        </div>
        <div className="section-domains">
          {showDomainsFields ? (
            <AddDomainForm
              domains={domains}
              setDomains={setDomains}
              secureGateway={props.secureGateway}
              updateDomains={props.updateDomains}
              setShowDomainsFields={setShowDomainsFields}
            />
          ) : (
            <div className="type-ctn domains-ctn">
              <div className="title-domains">
                <p>Allowed Origins</p>
                {!showDomainsFields && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleShowAddDomainModal()}
                  >
                    <img src={PlusIcon} />
                    Add or Edit Domains
                  </Button>
                )}
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Domain</th>
                    <th>Created On</th>
                    <th>Last Update</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.secureGateway.allowed_origins.split(",").map((domain, index) => (
                    <tr key={index}>
                      <td>{domain}</td>
                      <td>
                        {Moment(props.secureGateway.created_at).format("lll")}
                      </td>
                      <td>
                        {Moment(props.secureGateway.updated_at).format("lll")}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="delete-connection-container">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => props.handleShowDelete()}
            >
              <img src={trashIcon} />
              Delete Connection
            </Button>
          </div>

        </div>
      </div>
    </>
  );
};

export default APIDetails;
