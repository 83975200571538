import React from 'react';
import AppLayout from './AppLayout';
import { connect } from 'react-redux';
import routes from '../constants/routes.json';
import { Route, Redirect } from 'react-router-dom';

const AppLayoutRoute = ({
    component: Component,
    currentUser,
    ...rest
}) => { 

    return (
      <Route {...rest} render={matchProps => (    
        currentUser ?                
          <AppLayout>
            <Component {...matchProps} />
          </AppLayout>
            : <Redirect to={{ pathname: routes.LOGIN }} />
      )} />
    )
};

const mapState = (state, props) => {    
    return {
        currentUser: state.user.user,
        localtion: props.location        
    };
};

export default connect(mapState)(AppLayoutRoute);
