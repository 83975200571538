import React from 'react';
import ApiSnippets from './APISnippets';
import Grid from '@material-ui/core/Grid';
import SnippetWarning from './SnippetWarning';

const AdditionalSecurity = ({secureGateway, selectFramework, selectTab, loading }) => {
  return (
    <>
      <SnippetWarning 
        selectTab={selectTab}
      />
      <p className="title-snippets-1">Snippets</p>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="type-ctn container-snippets mt-3">
            <ApiSnippets
              secureGateway={secureGateway}
              selectFramework={selectFramework}
            />
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalSecurity; 
