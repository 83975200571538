import React, {useState} from 'react';
import { Button } from "@material-ui/core";

const NewPasswordForm = ({
  error,
  message,
  newPassword
}) => {

  const [password, setPassword]               = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNewPassword = () => {
    const params = new URLSearchParams(window.location.search);
    newPassword(params, password, confirmPassword); 
  }

  return (
    <div className="registrer-form">
      <h1>
        Reset Password for <br /> <span>KOR Connect!</span>
      </h1>
      <p className="muted-text">Enter your new password</p>
      <div className="form-container">
        <form>
          <div className="error-login">{error && <span>{error}</span>}</div>
          {message &&<div className="success-login"> <span>{message}</span></div>}
          <div className="input-container">
            <p>New Password</p>
            <input
              type="password"
              value={password}
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="input-container">
            <p>Confirm New Password</p>
            <input
              type="password"
              value={confirmPassword}
              placeholder="Confirm Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <Button
            id="resetPassword"
            color="primary"
            variant="contained"
            onClick={() => handleNewPassword()}
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
}

export default NewPasswordForm;
