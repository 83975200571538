import React from "react";
import { withRouter } from "react-router-dom";
import APIICon from "../assets/images/APIIcon.svg";
import SingleURLIcon from "../assets/images/SingleURLIcon.svg";
import AdditionalIcon from "../assets/images/AdditionalIcon.svg";
import ConnectionDetailsIcon from "../assets/images/ConnectionDetailsIcon.svg";
import AllIcon from "../assets/images/AllIcon.svg";
import routes from "../constants/routes";
import { Button } from "@material-ui/core";

const MenuAPIDetails = withRouter(
  ({ secureGateway, history, setSelectTab, selectTab }) => {
    const handleReturnHome = () => {
      history.push(`${routes.HOME}`);
    };

    return (
      <div className="menu-details">
        <div>
          <div className="details-api-gateway-container">
            <div className="details-api-gateway-container-1">
              <div className="icon-api-gateway">
                <img src={APIICon} />
              </div>

              <div className="details-api-gateway">
                {secureGateway && (
                  <p className="title-api-gateway">{secureGateway.name}</p>
                )}
              </div>
            </div>

            <div className="button-show-all-connections">
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleReturnHome()}
              >
                <img src={AllIcon} />
              </Button>
            </div>
          </div>

          {selectTab === "single" && (
            <div className="sections-menu">
              <div
                className="section-menu section-menu-active"
                onClick={() => setSelectTab("single")}
              >
                <img src={SingleURLIcon} />
                <p>Single URL</p>
              </div>
              <div
                className="section-menu"
                onClick={() => setSelectTab("security")}
              >
                <img src={AdditionalIcon} />
                <p>Additional Security</p>
              </div>
              <div
                className="section-menu"
                onClick={() => setSelectTab("details")}
              >
                <img src={ConnectionDetailsIcon} />
                <p>Connection Details</p>
              </div>
            </div>
          )}

          {selectTab === "security" && (
            <div className="sections-menu">
              <div
                className="section-menu"
                onClick={() => setSelectTab("single")}
              >
                <img src={SingleURLIcon} />
                <p>Single URL</p>
              </div>
              <div
                className="section-menu section-menu-active"
                onClick={() => setSelectTab("security")}
              >
                <img src={AdditionalIcon} />
                <p>Additional Security</p>
              </div>
              <div
                className="section-menu"
                onClick={() => setSelectTab("details")}
              >
                <img src={ConnectionDetailsIcon} />
                <p>Connection Details</p>
              </div>
            </div>
          )}

          {selectTab === "details" && (
            <div className="sections-menu">
              <div
                className="section-menu"
                onClick={() => setSelectTab("single")}
              >
                <img src={SingleURLIcon} />
                <p>Single URL</p>
              </div>
              <div
                className="section-menu"
                onClick={() => setSelectTab("security")}
              >
                <img src={AdditionalIcon} />
                <p>Additional Security</p>
              </div>
              <div
                className="section-menu section-menu-active"
                onClick={() => setSelectTab("details")}
              >
                <img src={ConnectionDetailsIcon} />
                <p>Connection Details</p>
              </div>
            </div>
          )}
        </div>

        <div className="button-show-all-connections">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleReturnHome()}
          >
            <img src={AllIcon} />
            Show all connections
          </Button>
        </div>

        {/* {secureGateway && (
            <div className="security-type-container">
                <img src={SecurityType} />
                <div className="security-type">
                    <p>Security Type</p>
                    {secureGateway.security_level === '1' ? (
                        <p>Single URL</p>
                    ):(
                        <p>Single URL + Additional Security</p>
                    )}
                   
                </div>
            </div>
            )} */}

        {/* 
                {secureGateway && (
                    <>
                        {secureGateway.connection_mode === 'testing' && (
                            <div className="testing-prod-modes">
                                <div className="select-mode left-testing active-select-mode" onClick={() => handleSwitchEnvironment('testing')}>
                                    <p>Testing Mode</p>
                                </div>
                                <div className="select-mode right-prod" onClick={() => handleSwitchEnvironment('production')}>
                                    <p>Production Mode</p>
                                </div>
                            </div>
                        )}

                        {secureGateway.connection_mode === 'production' && (
                            <div className="testing-prod-modes">
                                <div className="select-mode left-testing" onClick={() => handleSwitchEnvironment('testing')}>
                                    <p>Testing Mode</p>

                                </div>
                                <div className="select-mode active-select-mode right-prod" onClick={() => handleSwitchEnvironment('production')}>
                                    <p>Production Mode</p>
                                </div>
                            </div>
                        )}
                    </>
                )} */}

        {/* {secureGateway && (
                    <>
                        {secureGateway.connection_mode === 'testing' && (
                            <div className="warning-testing">
                                <img src={WarningIcon} />
                                <p>Testing Mode is enabled - do not forget to disable it.</p>
                            </div>
                        )}
                    </>
                )} */}
      </div>
    );
  }
);

export default MenuAPIDetails;

