import React, { useState } from 'react';
import SyntaxHighlighter from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Button, Snackbar } from '@material-ui/core';

const HeaderInfo = (props) => {
    const [ open, setOpen ] = useState(false);
    const code = 
    `headers : { "x-api-key": "${props.secureGateway.usage_plan_token}" }`;

    const handleCopyHeader = () => {
        navigator.clipboard.writeText(code);
        setOpen(true);
    }
    const handleClose = (reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };



    return(
        <div className="type-ctn base-url-ctn">
            <div className="secure-base-url-ctn">
                <div className="div-opacity-details"></div>
                <p>Header</p>
            </div>
            
            <div className="headers-snippet">
            <SyntaxHighlighter
            language="js"
            style={nightOwl}
            showLineNumbers={false}
            wrapLines={true}
          >
            {code}
          </SyntaxHighlighter>
          </div>
            <div className="documentation-url">
                <p>Place this header that includes the KOR Connect public key in your API calls.</p>
                <p onClick={() => handleCopyHeader()}>Copy Header</p>
            </div>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={2000}
                message="The header code has been copied."
            />
        </div>  
    )
}

export default HeaderInfo;