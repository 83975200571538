import React from 'react';
import { Button } from "@material-ui/core";
import SecurityLevelGraph from '../assets/images/SecurityLevelGraph.svg';

const SecurityLevelBar = (props) => {
    return (
        <div className="security-level-ctn">
            <p className="title-security-level">Security Level</p>

            <div className="bar-button-ctn">
                {props.selectTab === 'single' ? (
                    <div className="level-bar">
                        <div className="background-level">
                            &nbsp;&nbsp;
                        </div>
                        <div className="progress-level">
                            &nbsp;&nbsp;
                        </div>

                    </div>
                ) : (
                    <>
                        <img src={SecurityLevelGraph} />
                    </>
                )}
                {props.selectTab === 'single' && (
                    <div className="additional-security-button">
                        <Button color="primary" onClick={() => props.setSelectTab('security')}>
                            Add Additional Security
                        </Button>
                    </div>
                )}
            </div>
            <p className="more-info">
                <a href="https://kor-comunity.gitlab.io/kor-connect/adir/SECURITY.html" target="_blank">More Info...</a>
            </p>
        </div>
    )
}

export default SecurityLevelBar;