import React from 'react';
import '../../assets/styles/ModalKor.scss';
import closeIcon from '../../assets/images/closeIcon.svg';
import AddDomainForm from '../../components/AddDomainForm';
import { useSpring, animated, useTransition, config } from 'react-spring'

const AddDomainModal = ({
    handleShowAddDomainModal,
    domains,
    setDomains,
    secureGateway,
    updateDomains,
    setShowDomainsFields,
    modal,
    handleCloseModal,
    setTypeUpdate,
    typeUpdate
}) => {
    const stylesAnimationEntry = useSpring({
        opacity: modal ? 1 : 0,
        config: {
            duration: 700,
            friction: 26
        }
    })

    const transitions = useTransition(modal, {
        items: modal,
        from: { x: 900 },
        enter: { x: 0 },
        leave: { x: 900 },
        reverse: modal,
        delay: 200,
        config: {
            mass: 1,
            tension: 170,
            friction: 26,
            clamp: false,
            precision: 0.01,
            velocity: 0,
        }
    })

    return (
        <>
            <div className="container-modal-kor">
                <animated.div
                    className="background-opacity"
                    style={stylesAnimationEntry}
                    onClick={() => handleCloseModal()}
                >
                </animated.div>
                {transitions(({ x }, item) =>
                    !item ? (
                        <animated.div style={{
                            x: x.to({ range: [0.0, 1.0], output: [0, 1] }),
                            transform: x.to(y => `translate3d(900px,0,0)`)
                          }} className="content-modal modal-add-domain" >
                            <div className="title-modal">
                                <div className="close-modal">
                                    <img src={closeIcon} onClick={() => handleCloseModal()} />
                                    <p>Add a New <span>Allowed Domain</span></p>
                                </div>
                                <div className="caption-modal">
                                    <p>Add a new allowed domain, you can edit the allowed domains too.</p>
                                </div>
                            </div>

                         

                            <AddDomainForm
                                domains={domains}
                                setDomains={setDomains}
                                secureGateway={secureGateway}
                                updateDomains={updateDomains}
                                setShowDomainsFields={setShowDomainsFields}
                                handleShowAddDomainModal={handleShowAddDomainModal}
                                setTypeUpdate={setTypeUpdate}
                            />
                        </animated.div>
                    ) : (
                        <animated.div style={{
                            x: x.to({ range: [0.0, 1.0], output: [0, 1] }),
                          }} className="content-modal modal-add-domain" >
                            <div className="title-modal">
                                <div className="close-modal">
                                    <img src={closeIcon} onClick={() => handleCloseModal()} />
                                    <p>Add a New <span>Allowed Domain</span></p>
                                </div>
                                <div className="caption-modal">
                                    <p>Add a new allowed domain, you can edit the allowed domains too.</p>
                                </div>
                            </div>
                            <AddDomainForm
                                domains={domains}
                                setDomains={setDomains}
                                secureGateway={secureGateway}
                                updateDomains={updateDomains}
                                setShowDomainsFields={setShowDomainsFields}
                                handleShowAddDomainModal={handleShowAddDomainModal}
                                setTypeUpdate={setTypeUpdate}
                            />
                        </animated.div>
                    ))}

            </div>
        </>
    )
}

export default AddDomainModal;
