import React, { useState } from "react";
import Moment from "moment";
import { withRouter } from "react-router-dom";
import { Button, Tooltip } from "@material-ui/core";
import "../assets/styles/ItemApi.scss";
import apiIcon from "../assets/images/APIIcon.svg";
import trashIcon from "../assets/images/TrashIcon.svg";
import detailsIcon from "../assets/images/detailsIcon.svg";
import routes from "../constants/routes";
import { Warning } from '@material-ui/icons';
import WarningIcon from '../assets/images/WarningIcon.svg';

const ItemApi = withRouter(
  ({ gateway, history, removeGateway, getSecureGateway, currentUser, handleShowDelete }) => {
    const handleViewDetails = () => {
      getSecureGateway(gateway.id);
      history.push(`${routes.CONNECTIONDETAILS}/${gateway.id}`);
    };

    const [open, setOpen] = useState(false);
    const handleClose = () => {
      setOpen(!open);
    }

    return (
      <>
        <div className="container-item">
          <div className="name-info">
            <div className="icon-api-item">
              <img src={apiIcon} />
            </div>
            <div className="api-name-info">
              <p className="name-api">{gateway.name}</p>
              <p className="last-updated">
                Last update{" "}
                <span>{Moment(gateway.updated_at).format("lll")}</span>
              </p>
              <div className="container-avatar-created-by">
                <p>Created by</p>
                <div className="avatar-created-by">
                  <div>{currentUser.name.charAt(0).toUpperCase()}</div>
                  <p>{currentUser.name}</p>
                </div>
              </div>

            </div>
          </div>

          <div className="endpoints-info">
            <div>{gateway.secure_url_endpoint}</div>
            <p>Endpoint</p>

          </div>
          {gateway.connection_mode === 'testing' ? (
            <>
              <div className="warning-ctn m-2 mobile-warning">
                <Tooltip title="This connection is in Testing Mode" 
                  open={open} 
                  onClose={handleClose} 
                  placement="left" >
                  <img src={WarningIcon} onClick={handleClose} />
                </Tooltip>
              </div>
              <div className="warning-ctn m-2 desktop-warning">
                <Tooltip title="This connection is in Testing Mode">
                  <img src={WarningIcon} />
                </Tooltip>
              </div>
            </>
          ):(
            <>
            <div className="warning-ctn m-2 mobile-warning">
            </div>
            <div className="warning-ctn m-2 desktop-warning">
            </div>
          </>
          )}
          <div className="view-details-button">
            <Button onClick={handleViewDetails}>
              <img src={detailsIcon} />
              View Details
            </Button>
            {/* <Button
              color="secondary"
              variant="contained"
              onClick={() => handleShowDelete(gateway)}
            >
              <img src={trashIcon} />
              Delete
            </Button> */}
          </div>

        </div>
      </>
    );
  }
);

export default ItemApi;
