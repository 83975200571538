import React, { useState, useEffect } from 'react';
import logoKor from '../assets/images/KorIcon.svg';
import confirmIllustration from '../assets/images/confirmAccount-01.svg';
import { Button, CircularProgress } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import routes from "../constants/routes";

const ErrorPage =  withRouter(({
    history
}) => {
    const handleLogIn = () => {
        history.push(`${routes.LOGIN}`);
    }

    return (
        <div className="confirm-account-container">
            <div className="nav-bar-page">
                <img src={logoKor} />
            </div>

            <div className="confirm-account-message">
                <img src={confirmIllustration} />
                <p>You have already confirmed your account</p>
                <Button
                    color="primary"
                    onClick={() => handleLogIn()}
                    variant="contained"
                >Log in now</Button>
            </div>
        </div>
    )
})

export default ErrorPage;