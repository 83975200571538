const vueCodeMain = (recaptchaKey) => {
  return `import { createApp } from 'vue'
import App from './App.vue'
import { VueReCaptcha } from 'vue-recaptcha-v3';

createApp(App)
  .use(VueReCaptcha, {siteKey: '${recaptchaKey}'})
  .mount('#app')`;
};

export default vueCodeMain;
