import produce from 'immer';
import {
  GET_SECURE_GATEWAY_BEGIN, GET_SECURE_GATEWAY_SUCCESS, GET_SECURE_GATEWAY_ERROR,
  SHOW_SERCURE_GATEWAY_BEGIN, SHOW_SERCURE_GATEWAY_SUCCESS, SHOW_SERCURE_GATEWAY_ERROR,
  CREATE_SECURE_GATEWAY_BEGIN, CREATE_SECURE_GATEWAY_SUCCESS, CREATE_SECURE_GATEWAY_ERROR,
  UPDATE_SECURE_GATEWAY_BEGIN, UPDATE_SECURE_GATEWAY_SUCCESS, UPDATE_SECURE_GATEWAY_ERROR,
  DELETE_SECURE_GATEWAY_BEGIN, DELETE_SECURE_GATEWAY_SUCCESS, DELETE_SECURE_GATEWAY_ERROR,
  SHOW_WARNING_BEGIN,
  UPDATE_RATE_LIMITER_BEGIN,
  UPDATE_RATE_LIMITER_SUCCESS,
  UPDATE_RATE_LIMITER_ERROR
} from '../actions/types';

const initialState = {
  error: null,
  done: false,
  loading: false,
  typeUpdate: null,
  gatewayError: null,
  secureGateway: null,
  secureGateways: null,
  createLoading: false,
}

const reducer = produce((draft, action) => {
  switch (action.type) {
    case GET_SECURE_GATEWAY_BEGIN:
    case SHOW_SERCURE_GATEWAY_BEGIN:
    case DELETE_SECURE_GATEWAY_BEGIN:
    case UPDATE_RATE_LIMITER_BEGIN:
      draft.error = null;
      draft.loading = true;
      return;
    case UPDATE_SECURE_GATEWAY_BEGIN:
      draft.error = null;
      draft.loading = true;
      draft.typeUpdate = action.typeUdate;
      return;
    case CREATE_SECURE_GATEWAY_BEGIN:
      draft.gatewayError = null;
      draft.createLoading = true;
      return;
    case SHOW_SERCURE_GATEWAY_SUCCESS:
      draft.error = null;
      draft.loading = false;
      draft.secureGateway = action.payload;
      return;
    case GET_SECURE_GATEWAY_SUCCESS:
    case DELETE_SECURE_GATEWAY_SUCCESS:
      draft.error = null;
      draft.loading = false;
      draft.secureGateways = action.payload;
      return;
    case CREATE_SECURE_GATEWAY_SUCCESS:
      draft.gatewayError = null;
      draft.createLoading = false;
      draft.secureGateways = action.payload;
      draft.done = action.done;
      return;
    case UPDATE_SECURE_GATEWAY_SUCCESS:
      draft.error = null;
      draft.loading = false;
      draft.typeUpdate = action.typeUpdate;
      draft.secureGateways = action.payload.historical;
      draft.secureGateway = action.payload.secure_gateway;
      return;
    case SHOW_WARNING_BEGIN:
      draft.error = null;
      draft.done = action.done;
      return;
    case UPDATE_RATE_LIMITER_SUCCESS:
      draft.error = null;
      draft.loading = false;
      draft.secureGateways = action.payload.historical;
      return;
    case GET_SECURE_GATEWAY_ERROR:
    case SHOW_SERCURE_GATEWAY_ERROR:
    case UPDATE_SECURE_GATEWAY_ERROR:
    case DELETE_SECURE_GATEWAY_ERROR:
    case UPDATE_RATE_LIMITER_ERROR:
      draft.loading = false;
      draft.error = action.error;
      return;
    case CREATE_SECURE_GATEWAY_ERROR:
      draft.createLoading = false;
      draft.gatewayError = action.error;
      return;
    default:
      return;
  } 
}, initialState);

export default reducer;
